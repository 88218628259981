import {VCTCommandServiceClient} from 'gabi-api-js/vct/command/vct_command_grpc_web_pb';
import {AddConclusionRequest, UpdateEventConfigurationRequest} from 'gabi-api-js/vct/command/vct_command_pb';
import {VCTQueryServiceClient} from 'gabi-api-js/vct/query/vct_query_grpc_web_pb';
import {SignalQuery, SignalSampleQuery, TimeQuery} from 'gabi-api-js/vct/query/vct_query_pb';
import {Empty} from 'google-protobuf/google/protobuf/empty_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {buildFullDate, formatJSDateToApiFullDate} from '@/services/api-requests/requests-utils';
import {
    parseVirtualCareConclusionToApiConclusion,
    parseVirtualCareEventConfigurationToApiEventConfiguration,
    parseVirtualCareSignalTypeToApiSignalType
} from '@/services/api-requests/virtual-care-requests-utils';
import {ApiTransportOptions} from '@/services/api-transport/transport-service';
import VirtualCareAddConclusionRequest from '@/types/virtual-care-add-conclusion-request';
import VirtualCareAggregatedSignalData from '@/types/virtual-care-aggregated-signal-data';
import VirtualCareAlerts from '@/types/virtual-care-alert';
import VirtualCareApiDate from '@/types/virtual-care-api-date';
import VirtualCareCaregiver from '@/types/virtual-care-caregiver';
import VirtualCareCaregiverLastCall from '@/types/virtual-care-caregiver-last-call';
import VirtualCareEvents from '@/types/virtual-care-events';
import VirtualCareHealthCondition from '@/types/virtual-care-health-condition';
import VirtualCarePatient from '@/types/virtual-care-patient';
import VirtualCareSessionToken from '@/types/virtual-care-session-token';
import VirtualCareSignalQuery from '@/types/virtual-care-signal-query';
import VirtualCareSignalSampleQuery from '@/types/virtual-care-signal-sample-query';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import {VirtualCareTimeQuery} from '@/types/virtual-care-time-query';
import VirtualCareUpdateEventConfigurationRequest from '@/types/virtual-care-update-event-configuration-request';

const serviceQueryClient = VCTQueryServiceClient;
const serviceCommandClient = VCTCommandServiceClient;

export class VirtualCareService {
    async getRequest(idToken: string, options: ApiTransportOptions) {
        options = Object.assign({
            modelName: null,
        }, options);

        switch(options.modelName) {
        case 'getPatient': {
            return this.getPatient(idToken);
        }

        case 'getCaregiver': {
            return this.getCaregiver(idToken);
        }

        case 'getFirstRecordingDate': {
            return this.getFirstRecordingDate(idToken);
        }

        case 'getHealthCondition': {
            return this.getHealthCondition(idToken);
        }

        case 'getCaregiverLastCall': {
            return this.getCaregiverLastCall(idToken);
        }

        case 'getEventConfiguration': {
            return this.getEventConfiguration(idToken);
        }

        case 'getAle': {
            return this.getAlerts(idToken, options.data as unknown as VirtualCareTimeQuery);
        }

        case 'getAlertsForSignal': {
            return this.getAlertsForSignal(idToken, options.data as unknown as VirtualCareSignalQuery);
        }

        case 'getAverageForPeriod': {
            return this.getAverageForPeriod(idToken, options.data as unknown as VirtualCareSignalQuery);
        }

        case 'getAggregatedSignal': {
            return this.getAggregatedSignal(idToken, options.data as unknown as VirtualCareSignalQuery);
        }

        case 'getEventsList': {
            return this.getEventsList(idToken, options.data as unknown as VirtualCareTimeQuery);
        }

        case 'getSignalSample': {
            return this.getSignalSample(idToken, options.data as unknown as VirtualCareSignalSampleQuery);
        }

        case 'getRTCSessionToken': {
            return this.getRTCSessionToken(idToken);
        }

        case 'ping': {
            return this.ping(idToken);
        }

        case 'updateEventConfiguration': {
            return this.updateEventConfiguration(idToken, options.data as unknown as VirtualCareUpdateEventConfigurationRequest);
        }

        case 'addConclusion': {
            return this.addConclusion(idToken, options.data as unknown as VirtualCareAddConclusionRequest);
        }

        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    getPatient(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPatient', req)
                .then((response: VirtualCarePatient) => {
                    return response;
                })
        );
    }

    getCaregiver(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getCaregiver', req)
                .then((response: VirtualCareCaregiver) => {
                    return response;
                })
        );
    }

    getFirstRecordingDate(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getFirstRecordingDate', req)
                .then((response: VirtualCareApiDate) => {
                    return response;
                })
        );
    }

    getHealthCondition(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getHealthCondition', req)
                .then((response : VirtualCareHealthCondition) => {
                    return response;
                })
        );
    }

    getCaregiverLastCall(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getCaregiverLastCall', req)
                .then((response: VirtualCareCaregiverLastCall) => {
                    return response;
                })
        );
    }

    getEventConfiguration(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getEventConfiguration', req)
                .then((response: VirtualCareSignalsEventConfiguration) => {
                    return response;
                })
        );
    }

    getAlerts(idToken: string, data: VirtualCareTimeQuery) {
        const req = new TimeQuery();
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.from)));
        req.setTo(buildFullDate(formatJSDateToApiFullDate(data.to)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAle', req)
                .then((response: VirtualCareAlerts) => {
                    return response;
                })
        );
    }

    getAlertsForSignal(idToken: string, data: VirtualCareSignalQuery) {
        const req = new SignalQuery();
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.from)));
        req.setTo(buildFullDate(formatJSDateToApiFullDate(data.to)));
        req.setSignal(parseVirtualCareSignalTypeToApiSignalType(data.signal));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAlertsForSignal', req)
                .then((response: VirtualCareAlerts) => {
                    return response;
                })
        );
    }

    getAverageForPeriod(idToken: string, data: VirtualCareSignalQuery) {
        const req = new SignalQuery();
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.from)));
        req.setTo(buildFullDate(formatJSDateToApiFullDate(data.to)));
        req.setSignal(parseVirtualCareSignalTypeToApiSignalType(data.signal));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAverageForPeriod', req)
                .then(response => {
                    return response;
                })
        );
    }

    getAggregatedSignal(idToken: string, data: VirtualCareSignalQuery) {
        const req = new SignalQuery();
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.from)));
        req.setTo(buildFullDate(formatJSDateToApiFullDate(data.to)));
        req.setSignal(parseVirtualCareSignalTypeToApiSignalType(data.signal));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAggregatedSignal', req)
                .then((response: VirtualCareAggregatedSignalData) => {
                    return response;
                })
        );
    }

    getEventsList(idToken: string, data: VirtualCareTimeQuery) {
        const req = new SignalQuery();
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.from)));
        req.setTo(buildFullDate(formatJSDateToApiFullDate(data.to)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getEventsList', req)
                .then((response: VirtualCareEvents) => {
                    return response;
                })
        );
    }

    getSignalSample(idToken: string, data: VirtualCareSignalSampleQuery) {
        const req = new SignalSampleQuery();
        req.setTimestamp(buildFullDate(formatJSDateToApiFullDate(data.timestamp)));
        req.setDurationInSeconds(data.durationInSeconds as unknown as number);
        req.setSignalType(parseVirtualCareSignalTypeToApiSignalType(data.signalType));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getSignalSample', req)
                .then((response: VirtualCareEvents) => {
                    return response;
                })
        );
    }

    getRTCSessionToken(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getRTCSessionToken', req)
                .then((response: VirtualCareSessionToken) => {
                    return response;
                })
        );
    }

    ping(idToken: string) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'ping', req)
                .then((response: Empty) => {
                    return response;
                })
        );
    }

    updateEventConfiguration(idToken: string, data: VirtualCareUpdateEventConfigurationRequest) {
        const req = new UpdateEventConfigurationRequest();
        req.setConfiguration(parseVirtualCareEventConfigurationToApiEventConfiguration(data.configuration));
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateEventConfiguration', req)
                .then((response: Empty) => {
                    return response;
                })
        );
    }

    addConclusion(idToken: string, data: VirtualCareAddConclusionRequest) {
        const req = new AddConclusionRequest();
        req.setConclusion(parseVirtualCareConclusionToApiConclusion(data.conclusion));
        return (
            grpcRequest(idToken, serviceCommandClient, 'addConclusion', req)
                .then((response: Empty) => {
                    return response;
                })
        );
    }
}
