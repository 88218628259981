export const PatientChildSexLabel = {
    0: 'UNKNOWN',
    1: 'MALE',
    2: 'FEMALE',
};

export const PatientChildSexEnum = {
    UNKNOWN: 0,
    MALE: 1,
    FEMALE: 2,
};
