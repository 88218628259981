import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import VirtualCareHcpConclusionForm from '@/components/business/virtual-care/conclusion/virtual-care-hcp-conclusion-form';
import {SuccessMessage} from '@/components/form/success-message';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette} from '@/themes/darkmode';
import VirtualCareConclusion from '@/types/virtual-care-conclusion';

const VirtualCareHcpConclusion = () => {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ success, setSuccess ] = useState<boolean>(false);

    const handleSavedConclusion = (conclusion: VirtualCareConclusion) => {
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'addConclusion',
            data: {
                conclusion: conclusion
            }
        })
            .then((response) => {
                setLoading(false);
                setSuccess(true);
                return response;
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <StyledWrapper>
            <header>
                <h2>{t(['What is your conclusion?', 'virtualCare.conclusion.title'])}</h2>
                <h3>{t(['Write your conclusion, it will be sent to the caregiver and the prescriber.', 'virtualCare.conclusion.subtitle'])}</h3>
            </header>

            <div className="virtual-care-conclusion-content">
                {success ?
                    <SuccessMessage successMessage={t(['Your conclusion has been successfully sent!', 'virtualCare.conclusion.success'])} />
                    :
                    <VirtualCareHcpConclusionForm loading={loading} onSaved={handleSavedConclusion} />
                }
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      .virtual-care-conclusion-content {
          width: 70%;
          margin: 0 auto;
          margin-top: 25px;
          background: ${colorPalette.secondBackground};
          padding:30px;
          border-radius:5px;
      }
  }
`;

export default VirtualCareHcpConclusion;
