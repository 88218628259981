import VirtualCareEvent from '@/types/virtual-care-event';

class VirtualCareEvents {
    constructor(
        readonly eventList: Array<VirtualCareEvent>
    ) {
    }

}

export default VirtualCareEvents;
