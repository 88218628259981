import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import Styled from 'styled-components';

import { buttonFilledHoverTheme } from '@/components/buttons/button-filled-hover-theme';
import { buttonFilledTheme } from '@/components/buttons/button-filled-theme';
import { buttonShortFilledHoverTheme } from '@/components/buttons/button-short-filled-hover-theme';
import { buttonShortTheme } from '@/components/buttons/button-short-theme';
import { buttonTextTheme } from '@/components/buttons/button-text-theme';
import { MiniLoadingView } from '@/components/static/mini-loading-view';
import { colors } from '@/themes/darkmode';

class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.string,
        displayIcon: PropTypes.bool,
        loading: PropTypes.bool,
        disabled: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        onClick: PropTypes.func,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }
    
    render() {
        const props = this.props;
        const buttonContent = (props.loading) ? <MiniLoadingView /> : props.children;
        
        return (
            <>
                <button className={props.className} disabled={props.disabled || props.loading} onClick={this.handleClick}>{buttonContent}</button>
            </>
        );
    }

    handleClick(e) {
        if(this.props.onClick) {
            this.props.onClick(e);
        }
    }
}

//language=SCSS
Button = Styled(Button)`
& {
    border:none;
    border-radius:5px;
    color:${colors.button.text};
    background-color:${colors.button.background};
    background-repeat:no-repeat;
    background-position: 10px center;
    padding: ${props => props.displayIcon ? '7px 15px 7px 40px' : '7px 15px'};
    background-size: 20px 17px;
    
    &:hover {
        background-color:${colors.button.backgroundHover};
    }
    
    &.button-create {
        background-image: ${props => props.displayIcon ? colors.buttonCreate.backgroundIcon : 'none'};
        ${buttonFilledTheme(colors.buttonCreate)}
    }

    &.button-active {
        background-image: ${props => props.displayIcon ? colors.buttonActive.backgroundIcon : 'none'};
        ${buttonFilledTheme(colors.buttonActive)}
    }
    
    &.button-text-edit {
        background-image: ${props => props.displayIcon ? colors.buttonEdit.backgroundIcon : 'none'};
        ${buttonTextTheme(colors.buttonEdit)}
    }

    &.button-short-edit {
        background-image: ${props => props.displayIcon ? colors.buttonEdit.backgroundIcon : 'none'};
        ${buttonShortTheme(colors.buttonEdit)}
    }

    &.button-short-filled-hover-edit {
        background-image: ${props => props.displayIcon ? colors.buttonEdit.backgroundIcon : 'none'};
        ${buttonShortFilledHoverTheme(colors.buttonEdit)}
    }

    &.button-short-extend {
        background-image: ${props => props.displayIcon ? colors.buttonExtend.backgroundIcon : 'none'};
        ${buttonShortTheme(colors.buttonExtend)}
    }

    &.button-short-minimize {
        background-image: ${props => props.displayIcon ? colors.buttonMinimize.backgroundIcon : 'none'};
        ${buttonShortTheme(colors.buttonMinimize)}
    }

    &.button-edit {
        background-image: ${props => props.displayIcon ? colors.buttonEdit.backgroundIcon : 'none'};
        ${buttonFilledTheme(colors.buttonEdit)}
    }

    &.button-edit-filled-hover {
        background-image: ${props => props.displayIcon ? colors.buttonEdit.backgroundIcon : 'none'};
        ${buttonFilledHoverTheme(colors.buttonEdit)}
    }

    &.button-text-delete {
        background-image: ${props => props.displayIcon ? colors.buttonDelete.backgroundIcon : 'none'};
        ${buttonTextTheme(colors.buttonDelete)}
    }

    &.button-short-delete {
        background-image: ${props => props.displayIcon ? colors.buttonDelete.backgroundIcon : 'none'};
        ${buttonShortTheme(colors.buttonDelete)}
    }

    &.button-short-filled-hover-delete {
        background-image: ${props => props.displayIcon ? colors.buttonDelete.backgroundIcon : 'none'};
        ${buttonShortFilledHoverTheme(colors.buttonDelete)}
    }

    &.button-delete {
        background-image: ${props => props.displayIcon ? colors.buttonDelete.backgroundIcon : 'none'};
        ${buttonFilledTheme(colors.buttonDelete)}
    }

    &.button-delete-filled-hover {
        background-image: ${props => props.displayIcon ? colors.buttonDelete.backgroundIcon : 'none'};
        ${buttonFilledHoverTheme(colors.buttonDelete)}
    }

    &.button-hide-filled-hover {
        background-image: ${props => props.displayIcon ? colors.buttonHide.backgroundIcon : 'none'};
        background-size:20px;
        ${buttonFilledHoverTheme(colors.buttonHide)}
    }
    
    &.button-cancel {
        background-image: ${props => props.displayIcon ? colors.buttonCancel.backgroundIcon : 'none'};
        margin-left: 10px;
        ${buttonFilledTheme(colors.buttonCancel)}
    }
    
    &.button-qrcode {
        background-image: ${props => props.displayIcon ? colors.buttonQrCode.backgroundIcon : 'none'};
        margin-left: 10px;
        background-size: 24px;
    }
    
    &:disabled {
        background-color: ${colors.button.backgroundDisabled};
        cursor:not-allowed;
    }
}
`;

export { Button };
