import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import VirtualCarePatientCaregiverDetails from '@/components/business/virtual-care/patient/virtual-care-patient-caregiver-details';
import VirtualCarePatientCaregiverLastCall from '@/components/business/virtual-care/patient/virtual-care-patient-caregiver-last-call';
import VirtualCarePatientDetails from '@/components/business/virtual-care/patient/virtual-care-patient-details';
import VirtualCarePatientGabiUsage from '@/components/business/virtual-care/patient/virtual-care-patient-gabi-usage';
import VirtualCarePatientHealthCondition from '@/components/business/virtual-care/patient/virtual-care-patient-health-condition';
import {colorPalette} from '@/themes/darkmode';

const VirtualCarePatient = () => {
    const { t } = useTranslation();

    return (
        <StyledWrapper>
            <header>
                <h2>{t(['I\'m listening', 'virtualCare.patient.title'])}</h2>
                <h3>{t(['Take the time to know your patient', 'virtualCare.patient.subtitle'])}</h3>
                <h4>{t(['Listen carefully and check the patient history', 'virtualCare.patient.description'])}</h4>
            </header>

            <div className="virtual-care-content" id="virtual-care-content-patient">
                <div>
                    <VirtualCarePatientDetails />
                    <VirtualCarePatientGabiUsage />
                    <VirtualCarePatientHealthCondition />
                </div>
                <div>
                    <VirtualCarePatientCaregiverDetails />
                    <VirtualCarePatientCaregiverLastCall />
                </div>
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      #virtual-care-content-patient {
          display:flex;
          width: 80%;
          margin: 0 auto;
          margin-top: 40px;
          > div {
              width: 50%;
              margin: 0 20px;
              > div {
                  margin-bottom: 20px;
              }
          }
      }
      .virtual-care-block-patient {
          background: ${colorPalette.secondBackground};
          padding: 20px;
          border-radius: 10px;
      }
  }
`;

export default VirtualCarePatient;
