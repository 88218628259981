import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';

class VirtualCareSignalsEventConfiguration {
    constructor(
        readonly hr: VirtualCareSignalEventConfiguration,
        readonly spo2: VirtualCareSignalEventConfiguration,
        readonly rr: VirtualCareSignalEventConfiguration,
    ) {}

}

export default VirtualCareSignalsEventConfiguration;
