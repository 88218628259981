// @ts-expect-error missing type definition
import localStorage from 'store/dist/store.legacy';

export const LocalStorage = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(key: string, def: any): any {
        const out = localStorage.get(key);
        if (typeof(out) === 'undefined') {
            return def;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return out as any;
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set(key: string, val: any) {
        localStorage.set(key, val);
    },
};
