export const GenderEnumLabel = {
    //0: 'GENDER_NOT_SET',
    1: 'MALE',
    2: 'FEMALE',
    3: 'OTHER',
};

export const GenderEnum = {
    //GENDER_NOT_SET: 0,
    MALE: 1,
    FEMALE: 2,
    OTHER: 3,
};
