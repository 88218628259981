import {useSyncExternalStore, JSX} from 'react';

import {ObservableVar} from '@/util/observable-var';

export type CurrentPage = {
    pageName: string;
    pagePath: {
        route: string;
        name: string;
    }[];
};

class CurrentPageService_ {
    readonly currentPage = new ObservableVar<CurrentPage>({
        pageName: '',
        pagePath: [],
    });

    setCurrentPage(page: CurrentPage) {
        this.currentPage.set(page);
    }
}

const CurrentPageService = new CurrentPageService_();

function useCurrentPage() {
    return useSyncExternalStore<CurrentPage>(
        CurrentPageService.currentPage.onChange,
        () => CurrentPageService.currentPage.value,
    );
}

type Props = { [key: string]: unknown };
function withCurrentPage(Component: JSX.ElementType) {
    function ComponentWithTutoStatus(props: Props) {
        const currentPage = useCurrentPage();

        const extraProps: Props = {};
        extraProps['currentPage'] = currentPage;

        return (
            <Component {...props} {...extraProps} />
        );
    }

    return ComponentWithTutoStatus;
}

export {CurrentPageService, useCurrentPage, withCurrentPage};
