import {GroupId} from 'gabi-api-js/group/common/group_common_pb';
import {PatientPermissionManagementCommandServiceClient} from 'gabi-api-js/patient/command/patient_command_grpc_web_pb';
import {AccessRequest} from 'gabi-api-js/patient/command/patient_command_pb';
import {PatientId} from 'gabi-api-js/signal/common/signal_common_pb';

import grpcRequest from '@/services/api-requests/grpc-request';

const serviceCommandClient = PatientPermissionManagementCommandServiceClient;

export class PatientPermissionManagementService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'patientGroupGrantAccess': {
            return this.patientGroupGrantAccess(idToken, options.data);
        }
        case 'patientGroupRevokeAccess': {
            return this.patientGroupRevokeAccess(idToken, options.data);
        }
        
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    patientGroupGrantAccess(idToken, data) {
        const req = new AccessRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setGroupId(new GroupId().setId(data.groupId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'grantAccess', req)
                .then(result => {
                    return result;
                })
        );
    }

    patientGroupRevokeAccess(idToken, data) {
        const req = new AccessRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setGroupId(new GroupId().setId(data.groupId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'revokeAccess', req)
                .then(result => {
                    return result;
                })
        );
    }
}
