import PropTypes from 'prop-types';
import Slider, {createSliderWithTooltip} from 'rc-slider';
import styled from 'styled-components';

import {SignalTypeEnum} from '@/enum/signal-type-enum';
import {colorPalette} from '@/themes/darkmode.js';
const SliderWithTooltip = createSliderWithTooltip(Slider);

const EventSensibilityConfigurationSlider = (
    {
        value,
        signalType,
        sliderMin,
        sliderMax,
        sliderStep,
        onChanged,
    }) => {

    const handleChange = (range) => {
        onChanged(range);
    };

    return (
        <StyledWrapper className={`threshold-slider threshold-slider_${signalType}`}>
            <SliderWithTooltip
                min={sliderMin}
                max={sliderMax}
                step={sliderStep}
                tipProps={{visible: true, placement: 'bottom'}}
                value={value}
                allowCross={false}
                onChange={(value) => {
                    handleChange(value);
                }}
            />
        </StyledWrapper>
    );
};

EventSensibilityConfigurationSlider.propTypes = {
    value: PropTypes.number.isRequired,
    signalType: PropTypes.number.isRequired,
    sliderMin: PropTypes.number.isRequired,
    sliderMax: PropTypes.number.isRequired,
    sliderStep: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      padding: 2px 10px 2px 20px;
      width: 150px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

    .rc-slider.rc-slider-disabled {
        display: none;
    }
    .rc-slider:not(.rc-slider-disabled) {
        .rc-slider-rail {
            background: ${colorPalette.clearColor};
            &:before {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background: ${colorPalette.activeColor};
                position: absolute;
                left: 0;
                top: -3px;
            }
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background: ${colorPalette.clearColor};
                position: absolute;
                right: 0;
                top: -3px;
            }
        }

        .rc-slider-track {
            background: ${colorPalette.clearColor};
        }

        .rc-slider-handle {
            width:20px;
            height:20px;
            margin-top:-8px;
            background:${colorPalette.activeColor};
            border-color:${colorPalette.frontColor};
        }

        .rc-slider-handle-dragging {
            box-shadow: 0 0 0 3px ${colorPalette.frontColor};
        }

        .rc-slider-tooltip-inner {
            padding: 2px;
            min-width: 24px;
            height: auto;
            font-size: 11px;
            line-height: 1;
            color: #fff;
            text-align: center;
            text-decoration: none;
            background-color: inherit;
            border-radius: 0;
            box-shadow: none;
        }
    }

        &.threshold-slider_${SignalTypeEnum.PULSE_RATE} .rc-slider:not(.rc-slider-disabled) {
            .rc-slider-track, .rc-slider-handle, .rc-slider-rail:before {
                background: ${colorPalette.signalTypeLight.pulseRate};
            }
        }
    
        &.threshold-slider_${SignalTypeEnum.SPO2} .rc-slider:not(.rc-slider-disabled) {
            .rc-slider-track, .rc-slider-handle, .rc-slider-rail:before {
                background: ${colorPalette.signalTypeLight.spo2};
            }
        }
    }
`;

export default EventSensibilityConfigurationSlider;
