import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import {colorPalette} from '@/themes/darkmode';

const kf = keyframes`
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
`;
const animation = css`${kf} 2s ease infinite`;

class RedDotBlinking extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        return (
            <StyledWrapper>
                <svg height="50" width="50" className="blinking">
                    <circle cx="20" cy="20" r="5" fill={colorPalette.danger} />
                    Sorry, your browser does not support inline SVG.
                </svg>
            </StyledWrapper>
        );
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    .blinking {
        animation: ${animation};
    }
}
`;

export { RedDotBlinking };
