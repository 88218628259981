import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import VirtualCareConclusion from '@/types/virtual-care-conclusion';

class VirtualCareCaregiverLastCall {
    constructor(
        readonly date: VirtualCareApiFullDate,
        readonly conclusion: VirtualCareConclusion,
        readonly alertsHaveBeenModified: boolean
    ) {}
}

export default VirtualCareCaregiverLastCall;
