import styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';
import {getVirtualCareSignalTypeIdentifier, VirtualCareSignalType} from '@/types/virtual-care-signal-type';

interface VirtualCareRealTimeAverageBarProps {
    signalType: VirtualCareSignalType,
    average: number,
    eventConfiguration: VirtualCareSignalEventConfiguration
}

interface StyledProps {
    signalType: VirtualCareSignalType,
    lowWidth: string,
    highWidth: string,
    averageWidth: string
}

const VirtualCareRealTimeAverageBar = ({signalType, average, eventConfiguration} : VirtualCareRealTimeAverageBarProps) => {
    let eventConfigurationLowWidth:number = 0;
    let eventConfigurationLowWidthPer:string = '0%';
    let eventConfigurationHighWidth:number = 100;
    let eventConfigurationHighWidthPer:string = '100%';
    let averageWidth:number = 0;
    let averageWidthPer:string = '0%';

    switch (signalType) {
    case VirtualCareSignalType.SIGNAL_PR: // 0 -> 200
        eventConfigurationLowWidth = eventConfiguration.low.value / 2;
        eventConfigurationHighWidth = 100 - (eventConfiguration.high.value / 2);
        averageWidth = average / 2;
        break;
    case VirtualCareSignalType.SIGNAL_SPO2: // 0 -> 100
        eventConfigurationLowWidth = eventConfiguration.low.value;
        eventConfigurationHighWidth = 100 - eventConfiguration.high.value;
        averageWidth = average;
        break;
    }

    eventConfigurationLowWidthPer = eventConfigurationLowWidth.toString() + '%';
    eventConfigurationHighWidthPer = eventConfigurationHighWidth.toString() + '%';
    averageWidthPer = averageWidth.toString() + '%';

    return (
        <StyledWrapper
            signalType={signalType}
            lowWidth={eventConfigurationLowWidthPer}
            highWidth={eventConfigurationHighWidthPer}
            averageWidth={averageWidthPer}
            className={`average-bar-${getVirtualCareSignalTypeIdentifier(signalType)}`}
        >
            <span className="event-configuration-low">
                <span className="bar-cursor">{eventConfiguration.low.value}</span>
            </span>
            {signalType !== VirtualCareSignalType.SIGNAL_SPO2 &&
                <span className="event-configuration-high">
                    <span className="bar-cursor">{eventConfiguration.high.value}</span>
                </span>
            }
            <span className="average">~{average}</span>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div<StyledProps>`
  && {
      width:100%;
      height: 30px;
      border-radius: 15px;
      margin: 50px 10px 0 10px;
      position: relative;
      background: ${colorPalette.thirdBackground};

      .event-configuration-low {
          background: ${props => colorPalette.signalType[getVirtualCareSignalTypeIdentifier(props.signalType)]};
          height: 100%;
          display: block;
          width: ${props => props.lowWidth};
          color: rgb(255, 251, 251);
          line-height: 30px;
          position: absolute;
          text-align: end;
          padding-right: 10px;
          border-radius: 15px 0 0 15px;
      }

      .event-configuration-high {
          background: ${props => colorPalette.signalType[getVirtualCareSignalTypeIdentifier(props.signalType)]};
          height: 100%;
          display: block;
          width: ${props => props.highWidth};
          color: rgb(255, 251, 251);
          line-height: 30px;
          position: absolute;
          right:0;
          text-align: start;
          padding-left: 10px;
          border-radius: 0 15px 15px 0;
      }
      
      .average {
          width: 50px;
          position: absolute;
          left: calc(${props => props.averageWidth} - 26px);
          top:-40px;
          background: ${colorPalette.thirdBackground};
          border-radius: 15px;
          border: 1px solid ${props => colorPalette.signalType[getVirtualCareSignalTypeIdentifier(props.signalType)]};
          padding:2px 7px;
          &:after {
              content:"";
              width:5px;
              height:15px;
              border-right: 1px solid ${props => colorPalette.signalType[getVirtualCareSignalTypeIdentifier(props.signalType)]};
              position:absolute;
              right: calc(50% - 1px);
              top: 100%;
          }
      }
  }
`;

export default VirtualCareRealTimeAverageBar;
