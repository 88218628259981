enum VirtualCareSignalType {
    SIGNAL_PR = 0,
    SIGNAL_SPO2 = 1,
    SIGNAL_RR = 2,
    SIGNAL_ACTIGRAPHY = 3,
}

const getVirtualCareSignalTypeUnit = (virtualCareSignalType: VirtualCareSignalType) => {
    switch(virtualCareSignalType) {
    case VirtualCareSignalType.SIGNAL_PR:
        return 'bpm';
    case VirtualCareSignalType.SIGNAL_SPO2:
        return '%';
    case VirtualCareSignalType.SIGNAL_RR:
        return 'brpm';
    case VirtualCareSignalType.SIGNAL_ACTIGRAPHY:
        return 'G';
    }
};

const getVirtualCareSignalTypeIdentifier = (virtualCareSignalType: VirtualCareSignalType) => {
    switch(virtualCareSignalType) {
    case VirtualCareSignalType.SIGNAL_PR:
        return 'pulseRate';
    case VirtualCareSignalType.SIGNAL_SPO2:
        return 'spo2';
    case VirtualCareSignalType.SIGNAL_RR:
        return 'respiratoryRate';
    case VirtualCareSignalType.SIGNAL_ACTIGRAPHY:
        return 'movements';
    }
};

const getVirtualCareSignalTypeEventConfigKey = (virtualCareSignalType: VirtualCareSignalType) => {
    switch(virtualCareSignalType) {
    case VirtualCareSignalType.SIGNAL_PR:
        return 'hr';
    case VirtualCareSignalType.SIGNAL_SPO2:
        return 'spo2';
    case VirtualCareSignalType.SIGNAL_RR:
        return 'rr';
    }
};


export {
    VirtualCareSignalType,
    getVirtualCareSignalTypeUnit,
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeEventConfigKey
};
