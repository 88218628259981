import VirtualCareApiStringDate from '@/types/virtual-care-api-string-date';
import VirtualCareBiometricValue from '@/types/virtual-care-biometric-value';

class VirtualCareAggregatedSignalDataBlock {
    constructor(
        readonly at: VirtualCareApiStringDate,
        readonly tsAt: number,
        readonly value: VirtualCareBiometricValue,
        readonly reliableValue: VirtualCareBiometricValue
    ) {}
}

export default VirtualCareAggregatedSignalDataBlock;
