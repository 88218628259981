const enum VirtualCareSkinType {
    NOT_SET = 0,
    FITZPATRICK_I = 1,
    FITZPATRICK_II = 2,
    FITZPATRICK_III = 3,
    FITZPATRICK_IV = 4,
    FITZPATRICK_V = 5,
    FITZPATRICK_VI = 6,
}

export default VirtualCareSkinType;
