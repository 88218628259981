import { useState, useEffect } from 'react';

import {BackendApiService} from '@/services/backend-api-service';
import RequestError from '@/types/request-error';
import VirtualCareHealthCondition from '@/types/virtual-care-health-condition';
import VirtualCareHealthConditionTypes from '@/types/virtual-care-health-condition-types';

const useApiVirtualCareGetHealthCondition = () => {
    const [healthCondition, setHealthCondition] = useState<VirtualCareHealthCondition | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getHealthCondition',
        })
            .then((response: { [key:string]:unknown }) => {
                const responseParsed = new VirtualCareHealthCondition(
                    response.healthConditionTypes as VirtualCareHealthConditionTypes,
                    response.healthConditionDetail as string
                );
                setHealthCondition(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { healthCondition, loading, error };
};

export default useApiVirtualCareGetHealthCondition;
