import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {ErrorMessage} from '@/components/form/error-message';
import {AppLogoSmall} from '@/components/static/app-logo-small';
import LoadingView from '@/components/static/loading-view';
import useApiVirtualCareGetFirstRecordingDate from '@/services/api-hooks/useApiVirtualCareGetFirstRecordingDate';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import VirtualCareApiDate from '@/types/virtual-care-api-date';
import {NewDateUtil} from '@/util/new-date-util';

const VirtualCarePatientGabiUsage = () => {
    const { t } = useTranslation();
    const { firstRecordingDate, loading, error } = useApiVirtualCareGetFirstRecordingDate();

    const getDurationInMonths = (firstRecordingDate: VirtualCareApiDate) => {
        const today = NewDateUtil().getTime();
        const firstDate = formatApiDateToJSDate(firstRecordingDate).getTime();
        const diffTime = Math.abs(today - firstDate);

        return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    };

    return (
        <StyledWrapper className="virtual-care-block-patient" id="virtual-care-patient-gabi-usage">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {firstRecordingDate &&
                <>
                    <AppLogoSmall />
                    <span>
                        {t([`Using Gabi for {{${getDurationInMonths(firstRecordingDate)}}} months`, 'virtualCare.patient.usingGabi'], { duration : getDurationInMonths(firstRecordingDate) })}
                    </span>
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      display:flex;
      align-items: center;
      
      div {
          margin-left: 15px;
          margin-right: 25px;
      }
  }
`;

export default VirtualCarePatientGabiUsage;
