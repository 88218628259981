import {Role} from 'gabi-api-js/group/common/group_common_pb';
import {Gender} from 'gabi-api-js/hcp/common/hcp_common_pb';
import {Sex, SkinType} from 'gabi-api-js/patient/common/patient_common_pb';
import {
    Sensibility,
    SignalEventConfiguration,
    SignalsEventConfiguration,
    Threshold,
} from 'gabi-api-js/signal/common/signal_common_pb';
import {
    Date as ApiDate,
    FullDate as ApiFullDate,
    Time as ApiTime,
    SignalType
} from 'gabi-api-js/signal/query/signal_query_pb';

import {GroupUserRole} from '@/components/business/analytics/group/group-user-role';
import {GenderEnum} from '@/enum/gender-enum';
import {PatientChildSexEnum} from '@/enum/patient-child-sex-enum';
import {SignalTypeEnum} from '@/enum/signal-type-enum';

export const buildGender = (gender) => {
    let requestGender = null;

    if (gender === GenderEnum.GENDER_NOT_SET) {
        requestGender = Gender.GENDER_NOT_SET;
    }
    if (gender === GenderEnum.MALE) {
        requestGender = Gender.MALE;
    }
    if (gender === GenderEnum.FEMALE) {
        requestGender = Gender.FEMALE;
    }
    if (gender === GenderEnum.OTHER) {
        requestGender = Gender.OTHER;
    }

    return requestGender;
};

export const buildEventConfiguration = (eventConfiguration) => {
    const requestEventConfiguration = new SignalsEventConfiguration();
    const hrSignalConfiguration = new SignalEventConfiguration();
    const spo2SignalConfiguration = new SignalEventConfiguration();
    const rrSignalConfiguration = new SignalEventConfiguration();

    hrSignalConfiguration.setLow(new Threshold().setValue(eventConfiguration.configuration.hr.low.value));
    hrSignalConfiguration.setHigh(new Threshold().setValue(eventConfiguration.configuration.hr.high.value));
    hrSignalConfiguration.setSensibility(new Sensibility().setSeconds(eventConfiguration.configuration.hr.sensibility.seconds));

    spo2SignalConfiguration.setLow(new Threshold().setValue(eventConfiguration.configuration.spo2.low.value));
    spo2SignalConfiguration.setHigh(new Threshold().setValue(eventConfiguration.configuration.spo2.high.value));
    spo2SignalConfiguration.setSensibility(new Sensibility().setSeconds(eventConfiguration.configuration.spo2.sensibility.seconds));

    rrSignalConfiguration.setLow(new Threshold().setValue(0));
    rrSignalConfiguration.setHigh(new Threshold().setValue(100));
    rrSignalConfiguration.setSensibility(new Sensibility().setSeconds(0));

    requestEventConfiguration.setHr(hrSignalConfiguration);
    requestEventConfiguration.setSpo2(spo2SignalConfiguration);
    requestEventConfiguration.setRr(rrSignalConfiguration);

    return requestEventConfiguration;
};

export const buildSignalType = (signalType) => {
    let requestSignalType = SignalType.SIGNAL_ACTIGRAPHY;

    if (signalType === SignalTypeEnum.PULSE_RATE) {
        requestSignalType = SignalType.SIGNAL_PR;
    }
    if (signalType === SignalTypeEnum.SPO2) {
        requestSignalType = SignalType.SIGNAL_SPO2;
    }
    if (signalType === SignalTypeEnum.MOVEMENTS) {
        requestSignalType = SignalType.SIGNAL_ACTIGRAPHY;
    }

    return requestSignalType;
};

export const buildDate = (data) => {
    const apiDate = new ApiDate();

    apiDate.setDay(data.dateDay);
    apiDate.setMonth(data.dateMonth);
    apiDate.setYear(data.dateYear);

    return apiDate;
};

export const buildFullDate = (data) => {
    const apiFullDate = new ApiFullDate();
    const apiDate = new ApiDate();
    const apiTime = new ApiTime();

    apiDate.setDay(data.dateDay);
    apiDate.setMonth(data.dateMonth);
    apiDate.setYear(data.dateYear);

    apiTime.setHour(data.dateHour);
    apiTime.setMinutes(data.dateMinutes);
    apiTime.setSeconds(data.dateSeconds);

    apiFullDate.setDate(apiDate);
    apiFullDate.setTime(apiTime);

    return apiFullDate;
};

export const formatJSDateToApiDate = (JSDate) => {
    return {
        dateDay: JSDate.getDate(),
        dateMonth: JSDate.getMonth() + 1,
        dateYear: JSDate.getFullYear(),
    };
};

export const formatJSDateToApiFullDate = (JSDate) => {
    return {
        dateDay: JSDate.getDate(),
        dateMonth: JSDate.getMonth() + 1,
        dateYear: JSDate.getFullYear(),
        dateHour: JSDate.getHours(),
        dateMinutes: JSDate.getMinutes(),
        dateSeconds: JSDate.getSeconds()
    };
};

export const formatApiDateToJSDate = (ApiDate) => {
    return new Date(ApiDate.year, ApiDate.month-1, ApiDate.day);
};

export const formatApiFullDateToJSDate = (ApiDate) => {
    return new Date(ApiDate.date.year, ApiDate.date.month-1, ApiDate.date.day, ApiDate.time.hour ?? 0, ApiDate.time.minutes ?? 0, ApiDate.time.seconds ?? 0, ApiDate.time.miliSeconds ?? 0);
};

export const buildPatientSex = (patientSex) => {
    let requestPatientSex = null;

    if (patientSex === PatientChildSexEnum.FEMALE) {
        requestPatientSex = Sex.FEMALE;
    }
    if (patientSex === PatientChildSexEnum.MALE) {
        requestPatientSex = Sex.MALE;
    }

    return requestPatientSex;
};

export const buildPatientSkinType = (patientSkinType) => {
    let requestPatientSkinType = null;

    if (patientSkinType === SkinType.FITZPATRICK_I) {
        requestPatientSkinType = SkinType.FITZPATRICK_I;
    }
    if (patientSkinType === SkinType.FITZPATRICK_II) {
        requestPatientSkinType = SkinType.FITZPATRICK_II;
    }
    if (patientSkinType === SkinType.FITZPATRICK_III) {
        requestPatientSkinType = SkinType.FITZPATRICK_III;
    }
    if (patientSkinType === SkinType.FITZPATRICK_IV) {
        requestPatientSkinType = SkinType.FITZPATRICK_IV;
    }
    if (patientSkinType === SkinType.FITZPATRICK_V) {
        requestPatientSkinType = SkinType.FITZPATRICK_V;
    }
    if (patientSkinType === SkinType.FITZPATRICK_VI) {
        requestPatientSkinType = SkinType.FITZPATRICK_VI;
    }

    return requestPatientSkinType;
};

export const buildRole = (role) => {
    let requestRole = null;

    if (role === GroupUserRole.MEMBER) {
        requestRole = Role.MEMBER;
    }
    if (role === GroupUserRole.ADMIN) {
        requestRole = Role.ADMIN;
    }

    return requestRole;
};
