import {GroupCommandServiceClient} from 'gabi-api-js/group/command/group_command_grpc_web_pb';
import {
    AdminAddition,
    AdminRemoveRequest,
    GroupCreation, GroupRenameRequest,
    MemberAddition,
    MemberRemoval, RoleChangeRequest,
} from 'gabi-api-js/group/command/group_command_pb';
import {Access} from 'gabi-api-js/group/common/group_common_pb';
import {EntityId, GroupId, UserId} from 'gabi-api-js/group/common/group_common_pb';
import {GroupQueryServiceClient} from 'gabi-api-js/group/query/group_query_grpc_web_pb';
import {Entity} from 'gabi-api-js/group/query/group_query_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {buildRole} from '@/services/api-requests/requests-utils';

const serviceQueryClient = GroupQueryServiceClient;
const serviceCommandClient = GroupCommandServiceClient;

export class GroupService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'groups': {
            return this.fetchGroups(idToken);
        }
        case 'getHcpGroups': {
            return this.fetchHcpGroups(idToken, options.data);
        }
        case 'createGroup': {
            return this.createGroup(idToken, options.data);
        }
        case 'getPrescribers': {
            return this.fetchPrescribers(idToken);
        }
        case 'getPatientGroups': {
            return this.fetchPatientGroups(idToken, options.data);
        }
        case 'addUserAdminToGroup': {
            return this.addUserAdminToGroup(idToken, options.data);
        }
        case 'addUserMemberToGroup': {
            return this.addUserMemberToGroup(idToken, options.data);
        }
        case 'removeUserAdminFromGroup': {
            return this.removeUserAdminFromGroup(idToken, options.data);
        }
        case 'removeUserMemberFromGroup': {
            return this.removeUserMemberFromGroup(idToken, options.data);
        }
        case 'renameGroup': {
            return this.renameGroup(idToken, options.data);
        }
        case 'deleteGroup': {
            return this.deleteGroup(idToken, options.data);
        }
        case 'getGroup': {
            return this.getGroup(idToken, options.data);
        }
        case 'changeUserRole': {
            return this.changeUserRole(idToken, options.data);
        }
        
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    fetchGroups(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'findAll', req)
        );
    }

    fetchHcpGroups(idToken, data) {
        const req = new Entity();
        req.setId(new EntityId().setId(data.id));
        return (
            grpcRequest(idToken, serviceQueryClient, 'findAccess', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    fetchPatientGroups(idToken, data) {
        const req = new Entity();
        req.setId(new EntityId().setId(data.patientId.id));
        req.setDomain('patients');
        req.setAccess(Access.OWNER);
        return (
            grpcRequest(idToken, serviceQueryClient, 'findAccess', req)
        );
    }

    createGroup(idToken, data) {
        const req = new GroupCreation();
        req.setGroupName(data.name);
        return (
            grpcRequest(idToken, serviceCommandClient, 'create', req)
                .then(result => {
                    return result;
                })
        );
    }

    fetchPrescribers(idToken) {
        const req = new Empty();

        return (
            grpcRequest(idToken, serviceQueryClient, 'findAllMembers', req)
                .then(allResults => {
                    return allResults.idsList;
                })
        );
    }

    addUserAdminToGroup(idToken, data) {
        const req = new AdminAddition();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setUserId(new UserId().setId(data.userId));
        return (
            grpcRequest(idToken, serviceCommandClient, 'addAdmin', req)
                .then(result => {
                    return result;
                })
        );
    }

    addUserMemberToGroup(idToken, data) {
        const req = new MemberAddition();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setUserId(new UserId().setId(data.userId));
        return (
            grpcRequest(idToken, serviceCommandClient, 'addMember', req)
                .then(result => {
                    return result;
                })
        );
    }

    removeUserAdminFromGroup(idToken, data) {
        const req = new AdminRemoveRequest();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setUserId(new UserId().setId(data.userId));
        return (
            grpcRequest(idToken, serviceCommandClient, 'removeAdmin', req)
                .then(result => {
                    return result;
                })
        );
    }

    removeUserMemberFromGroup(idToken, data) {
        const req = new MemberRemoval();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setUserId(new UserId().setId(data.userId));
        return (
            grpcRequest(idToken, serviceCommandClient, 'removeMember', req)
                .then(result => {
                    return result;
                })
        );
    }

    renameGroup(idToken, data) {
        const req = new GroupRenameRequest();
        req.setGroupId(new GroupId().setId(data.id));
        req.setNewName(data.name);
        return (
            grpcRequest(idToken, serviceCommandClient, 'rename', req)
                .then(result => {
                    return result;
                })
        );
    }

    deleteGroup(idToken, data) {
        const req = new GroupId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceCommandClient, 'delete', req)
                .then(result => {
                    return result;
                })
        );
    }

    getGroup(idToken, data) {
        const req = new GroupId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceQueryClient, 'get', req)
                .then(result => {
                    return result;
                })
        );
    }

    changeUserRole(idToken, data) {
        const req = new RoleChangeRequest();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setUserId(new UserId().setId(data.userId));
        req.setNewRole(buildRole(data.role));
        return (
            grpcRequest(idToken, serviceCommandClient, 'changeRole', req)
                .then(result => {
                    return result;
                })
        );
    }
}
