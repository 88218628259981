import {Time} from 'gabi-api-ts/v2/patient/common/patient_common';
import {Date as ApiDate} from 'gabi-api-ts/v2/signal/query/signal_query';

import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {NewDateUtil} from '@/util/new-date-util';

export const daysInMonth = (year: number): number[] => [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
];
const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * Get the current ApiDate based on the browser's date.
 * Based on NewDateUtil, which can be overriden in tests
 */
export function apiDateToday(): ApiDate {
    return formatJSDateToApiDateTs(NewDateUtil());
}

export function formatApiDateUniversal(date: ApiDate) {
    if (date) {
        const day = (date.day < 10) ? `0${date.day}` : date.day;
        const month = monthNames[date.month-1];
        const year = date.year;

        return day + '-' + month + '-' + year;
    }
    return ''; // should not happen with Typescript, but here for safety
}

export function formatApiDateShortUniversal(date: ApiDate) {
    if (date) {
        const day = (date.day < 10) ? `0${date.day}` : date.day;
        const month = monthNames[date.month-1];

        return day + '-' + month;
    }
    return ''; // should not happen with Typescript, but here for safety
}

export function formatApiTimeUniversal(time: Time) {
    if (time) {
        const hours = (time.hour < 10) ? `0${time.hour}` : time.hour;
        const minutes = (time.minutes < 10) ? `0${time.minutes}` : time.minutes;
        const seconds = (time.seconds < 10) ? `0${time.seconds}` : time.seconds;
        return hours + ':' + minutes + ':' + seconds;
    }
    return ''; // should not happen with Typescript, but here for safety
}

export function formatApiDateTimeUniversal(date: ApiDate, time: Time) {
    return `${formatApiDateUniversal(date)} ${formatApiTimeUniversal(time)}`;
}

export function formatApiDateTimeWeekday(date: ApiDate, time: Time) {
    const today = apiDateToday();
    const yesterday = subDays(today, 1);
    const isToday = isEqual(date, today);
    const isYesterday = isEqual(date, yesterday);

    if (isToday) {
        return `Today, ${formatApiTimeUniversal(time)}`;
    }
    else if (isYesterday) {
        return `Yesterday, ${formatApiTimeUniversal(time)}`;
    }
    else {
        const weekday = getDayOfWeekStr(date);
        return `${weekday}, ${formatApiTimeUniversal(time)}`;
    }
}

/**
 * Helper function to determine if a date is in a leap year
 */
export function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export function addDays(date: ApiDate, days: number): ApiDate {
    if (days < 0) {
        return subDays(date, -days);
    }
    // Days in each month for non-leap and leap years

    let {year, month, day} = date;

    // Add days in a loop
    while (days > 0) {
        const monthDays = daysInMonth(year)[month - 1];
        if (day + days > monthDays) {
            days -= monthDays - day + 1;
            day = 1;
            month += 1;
            if (month > 12) {
                month = 1;
                year += 1;
            }
        }
        else {
            day += days;
            days = 0;
        }
    }

    return {year, month, day};
}

export function subDays(date: ApiDate, days: number): ApiDate {
    if (days < 0) {
        return addDays(date, -days);
    }

    let {year, month, day} = date;

    // Subtract days in a loop
    while (days > 0) {
        if (day > days) {
            day -= days;
            days = 0;
        }
        else {
            days -= day;
            month--;
            if (month < 1) {
                month = 12;
                year--;
            }
            day = daysInMonth(year)[month - 1];
        }
    }

    return {year, month, day};
}

export function getDayOfWeekStr(date: ApiDate): string {
    // Reference date: 1970-01-01 is a Saturday
    const referenceDate: ApiDate = { year: 1970, month: 1, day: 1 };
    const referenceDayIndex = 4; // Thursday

    const daysDifference = getDaysDifference(referenceDate, date);
    const dayIndex = (referenceDayIndex + daysDifference) % 7;

    return weekdays[dayIndex];
}

/**
 * Calculate total days between two dates
 */
export function getDaysDifference(from: ApiDate, to: ApiDate): number {
    let totalDays = 0;

    if (isAfter(from, to)) {
        return -1 * getDaysDifference(to, from);
    }

    // Case 1: Same year
    if (from.year === to.year) {
        if (from.month === to.month) {
            totalDays += to.day - from.day;
        }
        else {
            totalDays += daysInMonth(from.year)[from.month - 1] - from.day; // Remaining days in the starting month
            for (let m = from.month; m < to.month - 1; m++) {
                totalDays += daysInMonth(from.year)[m];
            }
            totalDays += to.day; // Days in the ending month
        }
    }
    // Case 2: Different years
    else {
        // Add remaining days in the starting year
        totalDays += getDaysDifference(from, {...from, month: 12, day: 31}) + 1;

        // Add full years in between
        for (let y = from.year + 1; y < to.year; y++) {
            totalDays += isLeapYear(y) ? 366 : 365;
        }

        totalDays += getDaysDifference({year: to.year, month: 1, day: 1}, to);
    }

    return totalDays;
}

/**
 * Compares two ApiDate objects and determines if they are equal.
 */
export function isEqual(date1: ApiDate, date2: ApiDate): boolean {
    return (
        date1.year === date2.year &&
        date1.month === date2.month &&
        date1.day === date2.day
    );
}

/**
 * Compares two ApiDate objects and determines if the first date is before the second.
 */
export function isBefore(date1: ApiDate, date2: ApiDate): boolean {
    if (date1.year !== date2.year) {
        return date1.year < date2.year;
    }
    if (date1.month !== date2.month) {
        return date1.month < date2.month;
    }
    return date1.day < date2.day;
}

/**
 * Compares two ApiDate objects and determines if the first date is before or equal to the second.
 */
export function isBeforeOrEqual(date1: ApiDate, date2: ApiDate): boolean {
    return isBefore(date1, date2) || isEqual(date1, date2);
}

/**
 * Compares two ApiDate objects and determines if the first date is after the second.
 */
export function isAfter(date1: ApiDate, date2: ApiDate): boolean {
    if (date1.year !== date2.year) {
        return date1.year > date2.year;
    }
    if (date1.month !== date2.month) {
        return date1.month > date2.month;
    }
    return date1.day > date2.day;
}

/**
 * Compares two ApiDate objects and determines if the first date is after or equal to the second.
 */
export function isAfterOrEqual(date1: ApiDate, date2: ApiDate): boolean {
    return isAfter(date1, date2) || isEqual(date1, date2);
}

/**
 * Check that a JS Date is in the given interval, including the dates in the range (as timestamps).
 * @param dateToCheck the date to check
 * @param dateFrom the lowest value in the range
 * @param dateTo the highest value in the range
 */
export function isApiDateInInterval(dateToCheck: ApiDate, dateFrom: ApiDate, dateTo: ApiDate) {
    return (
        isAfterOrEqual(dateToCheck, dateFrom) &&
        isBeforeOrEqual(dateToCheck, dateTo)
    );
}
