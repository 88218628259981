import VirtualCareGender from '@/types/virtual-care-gender';
import VirtualCareLanguage from '@/types/virtual-care-language';

class VirtualCareCaregiver {
    constructor(
        readonly firstName: string,
        readonly lastName: string,
        readonly gender: VirtualCareGender,
        readonly language: VirtualCareLanguage
    ) {}
}

export default VirtualCareCaregiver;
