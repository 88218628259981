/**
 * A variable with an onChange event that is trigerred when it is changed
 */
class ObservableVar<T> {
    private _value: T;
    private _listeners: ((prevValue: T) => void)[] = [];

    public get value(): T {
        return this._value;
    }

    constructor(initialValue: T) {
        this._value = initialValue;
        this.onChange = this.onChange.bind(this);
    }

    public set(newValue: T) {
        if (newValue !== this._value) {
            const prevValue = this._value;
            this._value = newValue;
            for (const listener of this._listeners) {
                listener(prevValue);
            }
        }
    }

    public onChange(listener: ((prevValue: T) => void) | (() => void)) {
        this._listeners = [...this._listeners, listener];
        return () => {
            this._listeners = this._listeners.filter(l => l !== listener);
        };
    }
}

export {ObservableVar};
