export function buttonFilledTheme(colors) {
    //language=SCSS
    return `& {
        background-color: ${colors.background};
        color: ${colors.text};
        
        &:not(.no-hover):not([disabled]) {
            &:hover, &.test-hover {
                background-color: ${colors.backgroundHover || colors.background};
                color: ${colors.textHover || colors.text};
                text-decoration: none;
            }
            &:focus, &.test-focus {
                outline: unset;
                box-shadow: unset;
                background-color: ${colors.backgroundFocus || colors.background};
                color: ${colors.textFocus};
            }
            &:hover:active, &:active, &.test-active, &:not(:disabled):not(.disabled):active {
                color: ${colors.textHover};
                background-color: ${colors.backgroundActive || colors.backgroundHover || colors.background};
                outline: unset;
                border-color: ${colors.border};
            }
        }
        
        &.no-hover {
            &:hover:active, &:active, &.test-active, &:not(:disabled):not(.disabled):active {
                background-color: ${colors.background};
                color: ${colors.text};
                border-color: ${colors.border};
            }
        }
    }`;
}
