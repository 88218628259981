const gabiEnv = __GABI_ENV__;

const config = {
    appId: 'myapp',
    appName: 'Gabi Analytics',

    backendApi: {
        baseUrlNoDomain: 'unknown',
        //baseUrl: `${domain}/${clientDomain}`,
        baseUrl: 'unknown',
        baseUrlTemplates: 'unknown/templates',
        timeout: 1000,
    },

    auth0: gabiEnv === 'production' ? {
        domain: 'account.gabismartcare.com',
        returnTo: 'https://analytics.gabismartcare.com',
        clientId: 'KBAFoIXJk0ghlKHFG9lDR2L0wKOmrDgB',
        audience: 'gsc-pediarity-prod.eu.auth0.com',
        cacheLocation: 'memory',
    } : gabiEnv === 'acceptance' ? {
        domain: 'acceptance.account.gabismartcare.com',
        returnTo: 'https://acceptance.analytics.gabismartcare.com',
        clientId: '3fAM7t4cVUhvLTKBmmlPFmCuLM1Njua4',
        audience: 'gsc-pediarity-acc.eu.auth0.com',
        cacheLocation: 'memory',
    } : {
        domain: 'gabi-analytics-dev-w8sfj0vv.eu.auth0.com',
        returnTo: 'https://dev.analytics.gabismartcare.com',
        clientId: 'pVIDtnlaLf6GTMmkWh52QBNlAxpvGgpG',
        audience: 'gabi-analytics-dev-w8sfj0vv.eu.auth0.com',
        cacheLocation: 'localstorage',
    },

    auth0Test: {
        username: 'elza.weykmans+cypress@gabismartcare.com',
        password: 'e2e-testing-42',
        domain: 'gabi-analytics-dev-w8sfj0vv.eu.auth0.com',
        returnTo: 'https://dev.analytics.gabismartcare.com',
        clientId: 'pVIDtnlaLf6GTMmkWh52QBNlAxpvGgpG',
        audience: 'gabi-analytics-dev-w8sfj0vv.eu.auth0.com',
    },

    firebase: gabiEnv === 'production' ? {
        apiKey: 'AIzaSyB2Wab78WrONllG-F69xBQg6NqrnPdAaLE',
        authDomain: 'gsc-pediarity-prod.firebaseapp.com',
        projectId: 'gsc-pediarity-prod',
        storageBucket: 'gsc-pediarity-prod.appspot.com',
        messagingSenderId: '971130836252',
        appId: '1:971130836252:web:13928a67e9a09a3b0056d9'
    } : gabiEnv === 'acceptance' ? {
        apiKey: 'AIzaSyCni8AH_fELafkeMxHljFHBCuBOGXlpun0',
        authDomain: 'gsc-pediarity-acc.firebaseapp.com',
        projectId: 'gsc-pediarity-acc',
        storageBucket: 'gsc-pediarity-acc.appspot.com',
        messagingSenderId: '262927445296',
        appId: '1:262927445296:web:8167294d51e0d65a6f2cf5',
    } : {
        apiKey: 'AIzaSyBsOVCiHP8asPl92Lx0hqTvXKXwMlm4Qi4',
        authDomain: 'gsc-pediarity-dev.firebaseapp.com',
        projectId: 'gsc-pediarity-dev',
        storageBucket: 'gsc-pediarity-dev.appspot.com',
        messagingSenderId: '1035753187788',
        appId: '1:1035753187788:web:4e6dad3b7b725bb8639569',

    }/*{
        apiKey: 'AIzaSyDZcCMKZwryUuWTnV3PncY_4SWS_lx0L10',
        authDomain: 'gsc-hcp-dashboard.firebaseapp.com',
        projectId: 'gsc-hcp-dashboard',
        storageBucket: 'gsc-hcp-dashboard.appspot.com',
        messagingSenderId: '631627879094',
        appId: '1:631627879094:web:c300e7e54d1cbf2f0b8c17',
        measurementId: 'G-PPSLQ79KG6'
    }*/,

    cloudFunction: gabiEnv === 'production' ? {
        hcpConnectionNotification: 'https://europe-west1-gsc-pediarity-prod.cloudfunctions.net/hcp-connection-notification',
    } : gabiEnv === 'acceptance' ? {
        hcpConnectionNotification: 'https://europe-west1-gsc-pediarity-acc.cloudfunctions.net/hcp-connection-notification',
    } : {
        hcpConnectionNotification: 'https://europe-west1-gsc-pediarity-dev.cloudfunctions.net/hcp-connection-notification',
    },

    feedbackAlerts: {
        lifetime: 10, // Time for a feedback message to be displayed (in seconds)
    },

    dateFormats: {
        time: 'HH:mm',
        fullTime: 'HH:mm:ss',
        date: 'dd/MM/yyyy',
        dateTime: 'dd/MM/yyyy HH:mm',
        dateTimeDay: 'eeee dd/MM/yyyy HH:mm',
        dateTimeSeconds: 'dd/MM/yyyy HH:mm:ss',
    },
};

export default config;
