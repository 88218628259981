import VirtualCareSignalDataBlock from '@/types/virtual-care-signal-data-block';
import VirtualCareSignalEventBlock from '@/types/virtual-care-signal-event-block';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';

class VirtualCareSignalData {
    constructor(
        readonly type: VirtualCareSignalType,
        readonly blocksList: Array<VirtualCareSignalDataBlock>,
        readonly eventsList: Array<VirtualCareSignalEventBlock>
    ) {}
}

export default VirtualCareSignalData;
