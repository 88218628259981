import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Button} from '@/components/buttons/button';
import VirtualCareConclusion from '@/types/virtual-care-conclusion';

interface VirtualCareConclusionProps {
    loading: boolean,
    onSaved: (conclusion: VirtualCareConclusion) => void
}

const VirtualCareHcpConclusionForm = ({loading, onSaved}: VirtualCareConclusionProps) => {
    const { t } = useTranslation();
    const [conclusion, setConclusion] = useState<VirtualCareConclusion>(new VirtualCareConclusion(''));

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const field = e.target as HTMLTextAreaElement;
        setConclusion(new VirtualCareConclusion(field.value.toString()));
        isFilled();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSaved(conclusion);
        setConclusion(new VirtualCareConclusion(''));
    };

    const isFilled = () => {
        return (conclusion.conclusion !== '');
    };

    return (
        <StyledWrapper>
            <form onSubmit={handleSubmit}>
                <div className="fieldWrapper">
                    <label htmlFor="conclusion">{t(['Conclusion', 'virtualCare.conclusion.label'])}</label>
                    <textarea id="conclusion" name="conclusion" onChange={handleChange} placeholder={t(['Type your conclusion here...', 'virtualCare.conclusion.placeholder']).toString()} value={conclusion.conclusion} />
                </div>
                <div>
                    <Button type="submit" className={'button-create'} loading={loading} disabled={!isFilled()}>{t(['Send conclusion', 'virtualCare.conclusion.send'])}</Button>
                </div>
            </form>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      label {
          display:none;
      }
  }
`;

export default VirtualCareHcpConclusionForm;
