import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';

class VirtualCareSignalEventBlock {
    constructor(
        readonly unixTsFrom: number,
        readonly unixTsTo: number,
        readonly from: VirtualCareApiFullDate,
        readonly to: VirtualCareApiFullDate,
        readonly tsFrom: number,
        readonly tsTo: number
    ) {}
}

export default VirtualCareSignalEventBlock;
