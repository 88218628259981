function roundTo(number: number, decimal: number) {
    return +(Math.round(Number(`${number}e+${decimal}`)) + 'e-' + decimal).toLocaleString();
}

function parseIntOrDefault(value: string | {toString(): string}, def: number): number {
    const out = parseInt(value.toString(), 10);
    if (isNaN(out)) {
        return def;
    }
    else {
        return out;
    }
}

export {roundTo, parseIntOrDefault};
