import VirtualCareSignalData from '@/types/virtual-care-signal-data';

class VirtualCareSignalSampleData {
    constructor(
        readonly signalData: VirtualCareSignalData,
        readonly actigraphyData: VirtualCareSignalData
    ) {}
}

export default VirtualCareSignalSampleData;
