import VirtualCareHealthConditionTypes from '@/types/virtual-care-health-condition-types';

class VirtualCareHealthCondition {
    constructor(
        readonly healthConditionTypes: VirtualCareHealthConditionTypes,
        readonly healthConditionDetail: string
    ) {}
}

export default VirtualCareHealthCondition;
