import {PropsWithChildren, ReactElement, useEffect} from 'react';
import Styled from 'styled-components';

import {colorPalette, zIndices} from '@/themes/darkmode';

type ModalProps = PropsWithChildren<{
    className?: string;
    title?: string | ReactElement | null;
    onClosed?: () => void;
    hideCloseButton?: boolean;
    disableEscapeKey?: boolean;
}>;

function UnstyledModal({className, title, children, onClosed, hideCloseButton, disableEscapeKey}: ModalProps) {
    hideCloseButton = hideCloseButton ?? false;

    // Close modal on escape
    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            // Handle ESCAPE key press here
            if (!disableEscapeKey) {
                handleCloseModal();
            }
        }
    };

    const handleCloseModal = () => {
        if (onClosed) {
            onClosed();
        }
    };

    return (
        <div className={`gabi-modal ${className ?? ''}`}>
            <div className={'modal-content animate__animated animate__fadeIn animate__faster'}>
                {typeof title === 'string' ? (
                    <h3>{title}</h3>
                ) : title}
                {!hideCloseButton && (
                    <button className="modal-close" onClick={handleCloseModal}>&times;</button>
                )}
                <div>{children}</div>
            </div>
            <div className="modal-overlay" onClick={handleCloseModal} />
        </div>
    );
}

//language=SCSS
const Modal = Styled(UnstyledModal)`
& {
    .modal-content {
        background:${colorPalette.mainBackground};
        padding: 20px 30px 20px 30px;
        margin-left: 20px;
        border-radius: 5px;
        position: fixed;
        width: auto;
        top: 355px;
        left: 50%;
        transform: translate(-50%, -50%);
        border:none;
        box-shadow: 0 1px 3px ${colorPalette.darkColor};
        z-index: ${zIndices.overlays + 100};
        min-width: 400px;
        max-width: 800px;
        
        > h3 {
            font-size: 20px;
        }
        
        .modal-close {
            border:none;
            background:none;
            width:40px;
            height:40px;
            position:absolute;
            right: 5px;
            top: 5px;
            color: ${colorPalette.frontColor};
            font-size: 25px;
            &:hover {
                outline: none;
                color:${colorPalette.clearColor};
            }
        }
    }    
    
    .modal-overlay {
        width: 100%;
        height: 100%;
        background: ${colorPalette.thirdBackground};
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.8;
        z-index: ${zIndices.overlays};
    }
}`;

export { Modal };
