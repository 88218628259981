import {TableSortableColumn, TableSorting} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

type TableSortableHeadProps<DataItem> = {
    columns: TableSortableColumn<DataItem>[];
    tableSorting: TableSorting;

    onSortingChange: (newTableSorting: TableSorting) => void;
}

function TableSortableHead<DataItem>({ columns, tableSorting, onSortingChange }: TableSortableHeadProps<DataItem>) {
    const handleSortingChange = (columnId: string) => {
        if (tableSorting.id !== columnId) {
            onSortingChange({
                id: columnId,
                sortByOrder: 'asc',
            });
        }
        else {
            const newOrder = (() => {
                switch (tableSorting.sortByOrder) {
                case 'asc': return 'desc';
                case 'desc': return 'default';
                default: return 'asc';
                }
            })();
            onSortingChange({
                ...tableSorting,
                sortByOrder: newOrder,
            });
        }
    };

    if (columns && columns.length > 0) {
        return (
            <thead>
                <tr>
                    {columns.map((column: TableSortableColumn<DataItem>) => {
                        const sortableClassName = (() => {
                            if (!column.sortable) {
                                return '';
                            }
                            else if (tableSorting.id !== column.id) {
                                return 'default';
                            }
                            else if (tableSorting.sortByOrder === 'asc') {
                                return 'up';
                            }
                            else if (tableSorting.sortByOrder === 'desc') {
                                return 'down';
                            }
                            else {
                                return 'default';
                            }
                        })();
                        return (
                            <th
                                key={column.id}
                                data-accessor={column.id}
                                onClick={column.sortable && column.id ? () => handleSortingChange(column.id) : undefined}
                                className={`${column.className ?? ''} ${sortableClassName}`}
                            >
                                {column.label}
                                {column.subLabel ? <span>{column.subLabel}</span> : ''}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortableHead" />;
    }
}

export {TableSortableHead};
