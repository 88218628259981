import styled from 'styled-components';

import VirtualCareNavigation from '@/components/business/virtual-care/virtual-care-navigation';
import {AppLogoFull} from '@/components/static/app-logo-full';
import {colorPalette} from '@/themes/darkmode';

const VirtualCareSidebar = () => {
    return (
        <StyledWrapper>
            <div className="virtual-care-sidebar">
                <header>
                    {/* TODO : ENV VAR <img src={(__GABI_ENV__ === 'production') ? logoFullProd : (__GABI_ENV__ === 'acceptance') ? logoFullAcc : logoFullDev} width={150} alt="Gabi SmartCare" />*/}
                    <AppLogoFull height={40} className="login-logo"/>
                </header>

                <VirtualCareNavigation />
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      header {
          text-align: center;
          padding: 20px 20px 40px 20px;
      }
      
      .virtual-care-sidebar {
          background:${colorPalette.secondBackground};
          position:fixed;
          height:100%;
          width:250px;
          border-right: 1px solid ${colorPalette.darkColor};
      }
  }
`;

export default VirtualCareSidebar;
