import {Outlet, useParams} from 'react-router-dom';

import {FirestoreTransportService} from '@/services/api-transport/firestore-transport-service';
import {MockTransportService} from '@/services/api-transport/mock-transport-service';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiService} from '@/services/backend-api-service';
import '@/style';

// Display Gabi Environment and define Transport Service
console.log(`Gabi environment: "${__GABI_ENV__}"`);

if(__GABI_ENV__ === 'testing')  {
    BackendApiService.setTransportService(new MockTransportService());
} else {
    BackendApiService.setTransportService(new FirestoreTransportService());
}

// Root component, including common components surrounding active route
export default function RootVirtualCare() {
    const { token } = useParams();

    if(document.location.href.indexOf('virtual-care-error') < 0) {
        if(token) {
            Auth0Service.setAccessTokenManually(token);
        } else {
            document.location['href'] = '/virtual-care-error';
        }
    }

    return (
        <Outlet/>
    );
}
