import {getI18n} from 'react-i18next';

/**
 * @deprecated use SignalType from the protobuf API instead
 */
enum SignalTypeEnum {
    PULSE_RATE = 0,
    SPO2 = 1,
    MOVEMENTS = 3,
}

/**
 * @deprecated use signal-type-util.ts instead
 */
const getSignalTypeLabel = (signalType: SignalTypeEnum) => {
    switch(signalType) {
    case SignalTypeEnum.PULSE_RATE:
        return getI18n().t('global.pulseRate');
    case SignalTypeEnum.SPO2:
        return getI18n().t('global.spo2');
    case SignalTypeEnum.MOVEMENTS:
        return getI18n().t('global.movements');
    }
};

/**
 * @deprecated use signal-type-util.ts instead
 */
const getSignalTypeUnit = (signalType: SignalTypeEnum) => {
    switch(signalType) {
    case SignalTypeEnum.PULSE_RATE:
        return 'bpm';
    case SignalTypeEnum.SPO2:
        return '%';
    case SignalTypeEnum.MOVEMENTS:
        return 'G';
    }
};

/**
 * @deprecated use signal-type-util.ts instead
 */
const getSignalTypeIdentifier = (signalType: SignalTypeEnum) => {
    switch(signalType) {
    case SignalTypeEnum.PULSE_RATE:
        return 'pulseRate';
    case SignalTypeEnum.SPO2:
        return 'spo2';
    case SignalTypeEnum.MOVEMENTS:
        return 'movements';
    }
};

/**
 * @deprecated use signal-type-util.ts instead
 */
const getSignalTypeEventConfigKey = (signalType: SignalTypeEnum) => {
    switch(signalType) {
    case SignalTypeEnum.PULSE_RATE:
        return 'hr';
    case SignalTypeEnum.SPO2:
        return 'spo2';
    }
};

export {
    SignalTypeEnum,
    getSignalTypeEventConfigKey,
    getSignalTypeIdentifier,
    getSignalTypeLabel,
    getSignalTypeUnit,
};
