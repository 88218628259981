import deepEqual from 'deep-eql';
import React from 'react';
import Styled from 'styled-components';

import ErrorBoundary from '@/components/errors/error-boundary';
import config from '@/config';
import {CurrentPageService, withCurrentPage} from '@/services/current-page-service';
//import {currentPage, pagePath} from '@/store/models';

export function Page(options) {
    options = { ...options };

    return function(Component) {
        class PageWrapper extends React.Component {
            static propTypes = Component.propTypes;
            static displayName = `Page(${Component.displayName || Component.name})`;
            
            getPageName() {
                if (typeof options.name === 'function') {
                    return options.name(this.props);
                }
                else {
                    return options.name || Component.displayName || Component.name;
                }
            }

            getPagePath() {
                if (typeof options.pagePath === 'function') {
                    return options.pagePath(this.props);
                }
                else {
                    return options.pagePath || [];
                }
            }
            
            updatePageInfos() {
                const pageName = this.getPageName();
                const pagePath = this.getPagePath();
                
                CurrentPageService.setCurrentPage({
                    pageName: pageName,
                    pagePath: pagePath,
                });

                document.title = pageName ? `${pageName} | ${config.appName}` : config.appName;
            }

            componentDidMount() {
                super.componentDidMount && super.componentDidMount();
                this.updatePageInfos();
            }
            
            componentDidUpdate(prevProps) {
                if (!deepEqual(prevProps, this.props)) {
                    this.updatePageInfos();
                }
            }

            render() {
                const props = this.props;
                return (
                    <ErrorBoundary>
                        <Component {...props}/>
                    </ErrorBoundary>
                );
            }
        }

        //language=SCSS
        PageWrapper = Styled(PageWrapper)`
        & {
            height: 100%;
            //overflow-y: scroll;
        }
        `;
        
        return withCurrentPage(PageWrapper);
    };
}
