import {subDays} from 'date-fns';
import {BandAssignationStatus} from 'gabi-api-ts/v2/patient/common/patient_common';
import {HealthCondition, Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {EventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';

import {NewDateUtil} from '@/util/new-date-util';

const demoPatientBirthDate = subDays(NewDateUtil(), 142);
const demoPatientLastRecordingDate = subDays(NewDateUtil(), 1);
const demoPatientLastRecordingDataTime = NewDateUtil();

export const DemoPatient: { patient: Patient, eventConfiguration: EventConfiguration, healthCondition: HealthCondition|null } = {
    patient: {
        bandSerial: {
            serial: 'DEMO',
        },
        birthDate: {
            year: demoPatientBirthDate.getFullYear(),
            month: demoPatientBirthDate.getMonth() + 1,
            day: demoPatientBirthDate.getDate(),
        },
        caregiverInformation: undefined,
        assignationStatus: BandAssignationStatus.HAS_RECORDING,
        externalSource: undefined,
        firstName: 'Patient',
        // healthCondition: undefined,
        lastName: 'Demo',
        lastRecordingDataTime: {
            date: {
                year: demoPatientLastRecordingDataTime.getFullYear(),
                month: demoPatientLastRecordingDataTime.getMonth() + 1,
                day: demoPatientLastRecordingDataTime.getDate(),
            },
            time: {
                hour: 11,
                minutes: 30,
                seconds: 15,
            }
        },
        lastRecordingDate: {
            year: demoPatientLastRecordingDate.getFullYear(),
            month: demoPatientLastRecordingDate.getMonth() + 1,
            day: demoPatientLastRecordingDate.getDate(),
        },
        patientId: {
            id: 'demo',
        },
        sex: 2,
        skinType: 0,
        trialId: undefined,
    },
    eventConfiguration: {
        configuration: {
            hr: {
                high: {
                    value: 130,
                },
                low: {
                    value: 60,
                },
                sensibility: {
                    seconds: 5,
                }
            },
            rr: {
                high: {
                    value: 50,
                },
                low: {
                    value: 30,
                },
                sensibility: {
                    seconds: 5,
                }
            },
            spo2: {
                high: {
                    value: 100,
                },
                low: {
                    value: 94,
                },
                sensibility: {
                    seconds: 1,
                }
            }
        },
    },
    healthCondition: null,
    /*{
        healthConditionDetail: null,
        healthConditionTypes: { healthConditionTypes : [] },
    }*/
};
