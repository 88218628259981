import {SignalType} from 'gabi-api-js/vct/common/vct_common_pb';
import {useEffect, useState} from 'react';

import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {parseApiSignalTypeToVirtualCareSignalType} from '@/services/api-requests/virtual-care-requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import RequestError from '@/types/request-error';
import {VirtualCareAlertType} from '@/types/virtual-care-alert-type';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import VirtualCareEvent from '@/types/virtual-care-event';
import VirtualCareEvents from '@/types/virtual-care-events';
import {VirtualCareTimeQuery} from '@/types/virtual-care-time-query';

const useApiVirtualCareGetEventsList = ({from, to}: VirtualCareTimeQuery) => {
    const [events, setEvents] = useState<VirtualCareEvents | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getEventsList',
            data: {
                from: from,
                to: to
            }
        })
            .then((response: { [key:string]:unknown }) => {
                const responseEventList = response.eventList as Array<{ [key: string]: unknown }>;
                const responseEventListParsed = new Array<VirtualCareEvent>;

                if(responseEventList && responseEventList.length > 0) {
                    responseEventList.forEach((event: { [key: string]: unknown }) => {
                        if(event.signal === SignalType.SIGNAL_PR || event.signal === SignalType.SIGNAL_SPO2) {
                            const timestampJsDate = formatApiFullDateToJSDate(event.timestamp) as Date;
                            const responseEvent = new VirtualCareEvent(
                                event.timestamp as VirtualCareApiFullDate,
                                timestampJsDate.getTime(),
                                parseApiSignalTypeToVirtualCareSignalType(event.signal as SignalType),
                                event.alertType as VirtualCareAlertType,
                                event.durationInSeconds as number,
                            );

                            responseEventListParsed.push(responseEvent);
                        }
                    });
                }
                const responseParsed = new VirtualCareEvents(
                    responseEventListParsed
                );
                setEvents(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { events, loading, error };
};

export default useApiVirtualCareGetEventsList;
