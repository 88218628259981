import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';

export const domain_HR = [
    40,
    250,
];
export const domain_SPO2 = [
    70,
    100,
];
export const domain_RR = [
    0,
    60,
];
export const domain_ACTIGRAPHY = [
    0,
    0.5,
];

export const getDomainForSignalType = (signalType: VirtualCareSignalType) => {
    switch (signalType) {
    case VirtualCareSignalType.SIGNAL_PR:
        return domain_HR;
    case VirtualCareSignalType.SIGNAL_SPO2:
        return domain_SPO2;
    case VirtualCareSignalType.SIGNAL_RR:
        return domain_RR;
    case VirtualCareSignalType.SIGNAL_ACTIGRAPHY:
        return domain_ACTIGRAPHY;
    }
};
