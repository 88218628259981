import {addSeconds} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {
    CartesianGrid,
    Line,
    LineChart,
    ReferenceArea,
    ReferenceLine,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import {NameType, ValueType} from 'recharts/types/component/DefaultTooltipContent';
import styled from 'styled-components';

import {
    ChartAxisTickHourMinutesFromTimestamp
} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-minutes-from-timestamp';
import {
    getDomainForSignalType
} from '@/components/business/virtual-care/analytics/virtual-care-analytics-signals-domains';
import VirtualCareAnalyticsActigraphySignalSample
    from '@/components/business/virtual-care/analytics/virutal-care-analytics-actigraphy-signal-sample';
import {ErrorMessage} from '@/components/form/error-message';
import LoadingView from '@/components/static/loading-view';
import useApiVirtualCareGetSignalSample from '@/services/api-hooks/useApiVirtualCareGetSignalSample';
import {colorPalette} from '@/themes/darkmode.js';
import VirtualCareSignalEventConfiguration from '@/types/virtual-care-signal-event-configuration';
import {
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeUnit,
    VirtualCareSignalType
} from '@/types/virtual-care-signal-type';
import {formatDateTimeUniversal} from '@/util/date-utils';

interface VirtualCareAnalyticsSignalSampleProps {
    signalType: VirtualCareSignalType,
    timestamp: Date,
    durationInSeconds: number,
    eventConfiguration: VirtualCareSignalEventConfiguration
}

const VirtualCareAnalyticsSignalSample = ({signalType, timestamp, durationInSeconds, eventConfiguration}: VirtualCareAnalyticsSignalSampleProps) => {
    const { t } = useTranslation();
    const { signalSample, loading, error } = useApiVirtualCareGetSignalSample({
        timestamp,
        durationInSeconds,
        signalType
    });

    const renderTooltip = ({payload}: TooltipProps<ValueType, NameType>) => {
        if (payload) {
            const color = (payload[0]?.payload?.reliability && payload[0]?.payload?.reliableValue.value && payload[0]?.payload?.reliableValue.value !== 'noData') ?
                colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)] :
                colorPalette.clearColor;

            return (
                <div className="chart-tooltip">
                    <strong style={{color: color}}>
                        <div>{t(`global.${getVirtualCareSignalTypeIdentifier(signalType)}`)}:&nbsp;
                            {(payload[0]?.payload?.reliability && payload[0]?.payload?.reliableValue?.value) ?
                                Math.round(payload[0]?.payload?.reliableValue?.value) + getVirtualCareSignalTypeUnit(signalType)
                                : (payload[0]?.payload?.value === 'noData') ?
                                    t(['No data available', 'global.noDataAvailable']) :
                                    t(['Not Reliable', 'global.notReliable'])
                            }
                        </div>
                    </strong>
                    {formatDateTimeUniversal(new Date(payload[0]?.payload?.tsAt))}
                </div>
            );
        }
        return null;
    };
    
    return (
        <StyledWrapper className="virtual-care-block-analytics" id="virtual-care-analytics-signal-sample">
            {loading && <LoadingView />}
            {error && <ErrorMessage errorMessage={error.message} />}
            {signalSample &&
                <>
                    <h3>{formatDateTimeUniversal(timestamp)} - {formatDateTimeUniversal(addSeconds(timestamp, 300))}</h3>
    
                    <div>
                        <LineChart data={signalSample.signalData.blocksList} width={450} height={200}>
                            <CartesianGrid strokeDasharray="1 3" />

                            <XAxis dataKey="tsAt" tick={<ChartAxisTickHourMinutesFromTimestamp textAnchor="right" />} interval={60} />
                            <YAxis domain={getDomainForSignalType(signalType)} unit={getVirtualCareSignalTypeUnit(signalType)} type="number" tickCount={4} />

                            {signalSample?.signalData.eventsList.map(block => {
                                if(block.tsFrom === block.tsTo) {
                                    return (
                                        <ReferenceLine
                                            key={`eventBlock-${getVirtualCareSignalTypeUnit(signalType)}-${block.tsFrom}`}
                                            x={block.tsFrom}
                                            stroke={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                                            strokeWidth={0.5}
                                        />
                                    );
                                } else {
                                    return (
                                        <ReferenceArea
                                            key={block.tsFrom}
                                            x1={block.tsFrom}
                                            x2={block.tsTo}
                                            fill={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                                            strokeOpacity={0.0}
                                            fillOpacity={0.25}
                                        />
                                    );
                                }
                            })}

                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="value"
                                stroke={colorPalette.clearColor}
                                strokeWidth={1.2}
                                connectNulls={false}
                            />
                            <Line
                                isAnimationActive={false}
                                type="linear"
                                dot={false}
                                activeDot={false}
                                dataKey="reliableValue.value"
                                stroke={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                                strokeWidth={1.5}
                                opacity={1}
                                connectNulls={false}
                            />
                            {eventConfiguration &&
                            <ReferenceLine
                                y={eventConfiguration.high.value}
                                label={{
                                    position: (eventConfiguration.high.value >= (getDomainForSignalType(signalType)[1] - (5))) ? 'bottom' : 'top',
                                    value: `${eventConfiguration.high.value}bpm`,
                                    fill: colorPalette.clearColor
                                }}
                                stroke={colorPalette.clearColor}
                                strokeDasharray="4"
                            />
                            }
                            {eventConfiguration &&
                            <ReferenceLine
                                y={eventConfiguration.low.value}
                                label={{
                                    position: (eventConfiguration.low.value <= (getDomainForSignalType(signalType)[0] + (5))) ? 'top' : 'bottom',
                                    value: `${eventConfiguration.low.value}bpm`,
                                    fill: colorPalette.clearColor
                                }}
                                stroke={colorPalette.clearColor}
                                strokeDasharray="4"
                            />
                            }
                            <Tooltip
                                content={renderTooltip}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    </div>
    
                    <VirtualCareAnalyticsActigraphySignalSample actigraphyData={signalSample.actigraphyData} />
                </>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      margin-top:5px;
  }
`;

export default VirtualCareAnalyticsSignalSample;
