class Auth0Service_ {
    accessTokenProvider: () => Promise<string | null> = async () => null;

    setAccessTokenProvider(provider: () => Promise<string>) {
        this.accessTokenProvider = provider;
    }

    getAccessToken(): Promise<string | null> {
        return this.accessTokenProvider();
    }
}

const Auth0Service = new Auth0Service_();

export { Auth0Service };
