export function buttonShortFilledHoverTheme(colors) {
    //language=SCSS
    return `& {
        color: transparent;
        text-indent:-10000px;
        padding: 7px 20px;
        background-position: center;
        background-size: 15px;
        display: block;
        
        &:not(.no-hover):not([disabled]) {
            &:hover, &.test-hover {
                color: transparent;
                background-color: ${colors.backgroundHover};
            }
            &:focus, &.test-focus {
                outline: unset;
                box-shadow: unset;
                color: transparent;
            }
            &:hover:active, &:active, &.test-active, &:not(:disabled):not(.disabled):active {
                color: transparent;
                outline: unset;
                border-color: ${colors.border};
            }
        }
        
        &.no-hover {
            &:hover:active, &:active, &.test-active, &:not(:disabled):not(.disabled):active {
                color: transparent;
                border-color: ${colors.border};
            }
        }
    }`;
}
