import Styled from 'styled-components';

import logoSimpleProd from '@/public/images/logo-full.svg';
declare const __GABI_ENV__: string;

type AppLogoSmallProps = {
    className?: string;
    height?: number;
};

function AppLogoSmallUnstyled(props: AppLogoSmallProps) {
    const height = props.height ?? 50;
    return (
        <div className={props.className}>
            <img src={logoSimpleProd} alt="Gabi SmartCare" height={height}/>
            {__GABI_ENV__ === 'acceptance' && (
                <EnvLetter letter="A" color="#08997D"/>
            )}
            {__GABI_ENV__ === 'development' && (
                <EnvLetter letter="D" color="#BF1FBF"/>
            )}
            {__GABI_ENV__ === 'testing' && (
                <EnvLetter letter="T" color="#BF1FBF"/>
            )}
            {__GABI_ENV__ === 'preprod' && (
                <EnvLetter letter="P" color="#EF852E"/>
            )}
        </div>
    );
}

function EnvLetter({color, letter}: {color: string, letter: string}) {
    return (
        <div className="logo-full__env-letter" style={{backgroundColor: color}}>
            {letter}
        </div>
    );
}

//language=SCSS
const AppLogoSmall = Styled(AppLogoSmallUnstyled)`
& {
    display: inline-block;
    position: relative;
    height: ${props => props.height || 50}px;
    
    img {
        position: relative;
        height: ${props => props.height || 50}px;
    }
    
    .logo-full__env-letter {
        position: absolute;
        text-align: center;
        font-weight: 700;
        font-size: ${props => (props.height || 50)/50*16}px;
        border-radius: ${props => (props.height || 50)/50*15}px;
        left: ${props => (props.height || 50)/50*37}px;
        top: ${props => (props.height || 50)/50*-9}px;
        width: ${props => (props.height || 50)/50*20}px;
        height: ${props => (props.height || 50)/50*20}px;
        line-height: ${props => (props.height || 50)/50*20.5}px;
    }
}
`;

export { AppLogoSmall };
