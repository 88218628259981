const VirtualCarePatientMockData = {
    birthDate: {
        year: 2023,
        month: 1,
        day: 10
    },
    firstName: 'Jane',
    lastName: 'Mock',
    sex: 2,
    skinType: 2,
};

export default VirtualCarePatientMockData;
