import {SignalType} from 'gabi-api-js/vct/common/vct_common_pb';
import { useState, useEffect } from 'react';

import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {parseApiSignalTypeToVirtualCareSignalType} from '@/services/api-requests/virtual-care-requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import RequestError from '@/types/request-error';
import VirtualCareAggregatedSignalData from '@/types/virtual-care-aggregated-signal-data';
import VirtualCareAggregatedSignalDataBlock from '@/types/virtual-care-aggregated-signal-data-block';
import VirtualCareApiStringDate from '@/types/virtual-care-api-string-date';
import VirtualCareBiometricValue from '@/types/virtual-care-biometric-value';
import VirtualCareSignalQuery from '@/types/virtual-care-signal-query';

const useApiVirtualCareGetAggregatedSignal = ({from, to, signal}: VirtualCareSignalQuery) => {
    const [aggregatedSignal, setAggregatedSignal] = useState<VirtualCareAggregatedSignalData | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getAggregatedSignal',
            data: {
                from: from,
                to: to,
                signal: signal
            }
        })
            .then((response: { [key:string]:unknown }) => {
                const responseBlocksList = response.blocksList as Array<{ [key: string]: unknown }>;
                const responseBlocksListParsed = new Array<VirtualCareAggregatedSignalDataBlock>;

                if(responseBlocksList && responseBlocksList.length > 0) {
                    responseBlocksList.forEach((block: { [key: string]: unknown }) => {
                        const atJsDate = formatApiFullDateToJSDate(block.at) as Date;
                        const responseBlock = new VirtualCareAggregatedSignalDataBlock(
                            block.at as VirtualCareApiStringDate,
                            atJsDate.getTime(),
                            block.value as VirtualCareBiometricValue,
                            block.reliableValue as VirtualCareBiometricValue
                        );
                        responseBlocksListParsed.push(responseBlock);
                    });
                }
                const responseParsed = new VirtualCareAggregatedSignalData(
                    parseApiSignalTypeToVirtualCareSignalType(response.type as SignalType),
                    responseBlocksListParsed
                );
                setAggregatedSignal(responseParsed);
                setLoading(false);
                return response;
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { aggregatedSignal, loading, error };
};

export default useApiVirtualCareGetAggregatedSignal;
