import PropTypes from 'prop-types';
import styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode';

function ColoredContainer({title, color, className, children}) {
    return (
        <StyledWrapper color={color} className={`colored-container ${className ?? ''}`}>
            {title && <span className="colored-container__title">{title}</span>}
            {children}
        </StyledWrapper>
    );
}

ColoredContainer.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    background: ${colorPalette.secondBackground};
    border-radius: 5px;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: ${props => props.color || 'white'};
    padding: 10px 15px;
    
    > .colored-container__title {
        font-size: 14px;
        font-weight: normal;
    }
    
    h3 {
        font-size: 12px;
        margin-bottom: 0;
        color: ${colorPalette.clearColor};
    }
}
`;

export default ColoredContainer;
