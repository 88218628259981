import {SignalType} from 'gabi-api-js/vct/common/vct_common_pb';
import {useEffect, useState} from 'react';

import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {parseApiSignalTypeToVirtualCareSignalType} from '@/services/api-requests/virtual-care-requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import RequestError from '@/types/request-error';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import VirtualCareBiometricValue from '@/types/virtual-care-biometric-value';
import VirtualCareSignalData from '@/types/virtual-care-signal-data';
import VirtualCareSignalDataBlock from '@/types/virtual-care-signal-data-block';
import VirtualCareSignalEventBlock from '@/types/virtual-care-signal-event-block';
import VirtualCareSignalSampleData from '@/types/virtual-care-signal-sample-data';
import VirtualCareSignalSampleQuery from '@/types/virtual-care-signal-sample-query';

const useApiVirtualCareGetSignalSample = ({timestamp, durationInSeconds, signalType}: VirtualCareSignalSampleQuery) => {
    const [signalSample, setSignalSample] = useState<VirtualCareSignalSampleData | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getSignalSample',
            data: {
                timestamp: timestamp,
                durationInSeconds: durationInSeconds,
                signalType: signalType
            }
        })
            .then((response: { [key:string]:unknown }) => {
                const responseSignal = response.signalData as VirtualCareSignalData;
                const responseActigraphy = response.actigraphyData as VirtualCareSignalData;
                const responseSignalBlocksList = responseSignal.blocksList as unknown as Array<{ [key: string]: unknown }>;
                const responseActigraphyBlocksList = responseActigraphy.blocksList as unknown as Array<{ [key: string]: unknown }>;
                const responseSignalEventsList = responseSignal.eventsList as unknown as Array<{ [key: string]: unknown }>;
                const responseActigraphyEventsList = responseActigraphy.eventsList as unknown as Array<{ [key: string]: unknown }>;
                const responseSignalBlocksListParsed = new Array<VirtualCareSignalDataBlock>;
                const responseActigraphyBlocksListParsed = new Array<VirtualCareSignalDataBlock>;
                const responseSignalEventsListParsed = new Array<VirtualCareSignalEventBlock>;
                const responseActigraphyEventsListParsed = new Array<VirtualCareSignalEventBlock>;

                // SIGNAL DATA
                if(responseSignalBlocksList && responseSignalBlocksList.length > 0) {
                    responseSignalBlocksList.forEach((block: { [key: string]: unknown }) => {
                        const atJsDate = formatApiFullDateToJSDate(block.at) as Date;
                        const responseBlock = new VirtualCareSignalDataBlock(
                            block.at as VirtualCareApiFullDate,
                            atJsDate.getTime(),
                            block.value as number,
                            block.reliability as boolean,
                            block.reliableValue as VirtualCareBiometricValue,
                            block.unixTs as number
                        );
                        responseSignalBlocksListParsed.push(responseBlock);
                    });
                }

                // ACTIGRAPHY DATA
                if(responseActigraphyBlocksList && responseActigraphyBlocksList.length > 0) {
                    responseActigraphyBlocksList.forEach((block: { [key: string]: unknown }) => {
                        const atJsDate = formatApiFullDateToJSDate(block.at) as Date;
                        const responseBlock = new VirtualCareSignalDataBlock(
                            block.at as VirtualCareApiFullDate,
                            atJsDate.getTime(),
                            block.value as number,
                            block.reliability as boolean,
                            block.reliableValue as VirtualCareBiometricValue,
                            block.unixTs as number
                        );
                        responseActigraphyBlocksListParsed.push(responseBlock);
                    });
                }

                // SIGNAL EVENTS
                if(responseSignalEventsList && responseSignalEventsList.length > 0) {
                    responseSignalEventsList.forEach((event: { [key: string]: unknown }) => {
                        const fromJsDate = formatApiFullDateToJSDate(event.from) as Date;
                        const toJsDate = formatApiFullDateToJSDate(event.to) as Date;
                        const responseEvent = new VirtualCareSignalEventBlock(
                            event.unixTsFrom as number,
                            event.unixTsTo as number,
                            event.from as VirtualCareApiFullDate,
                            event.to as VirtualCareApiFullDate,
                            fromJsDate.getTime(),
                            toJsDate.getTime()
                        );
                        responseSignalEventsListParsed.push(responseEvent);
                    });
                }

                // ACTIGRAPHY EVENTS
                if(responseActigraphyEventsList && responseActigraphyEventsList.length > 0) {
                    responseActigraphyEventsList.forEach((event: { [key: string]: unknown }) => {
                        const fromJsDate = formatApiFullDateToJSDate(event.from) as Date;
                        const toJsDate = formatApiFullDateToJSDate(event.to) as Date;
                        const responseEvent = new VirtualCareSignalEventBlock(
                            event.unixTsFrom as number,
                            event.unixTsTo as number,
                            event.from as VirtualCareApiFullDate,
                            event.to as VirtualCareApiFullDate,
                            fromJsDate.getTime(),
                            toJsDate.getTime()
                        );
                        responseActigraphyEventsListParsed.push(responseEvent);
                    });
                }

                const responseSignalParsed = new VirtualCareSignalData(
                    parseApiSignalTypeToVirtualCareSignalType(response.type as SignalType),
                    responseSignalBlocksListParsed,
                    responseSignalEventsListParsed
                );

                const responseActigraphyParsed = new VirtualCareSignalData(
                    parseApiSignalTypeToVirtualCareSignalType(response.type as SignalType),
                    responseActigraphyBlocksListParsed,
                    responseActigraphyEventsListParsed
                );

                const responseParsed = new VirtualCareSignalSampleData(
                    responseSignalParsed,
                    responseActigraphyParsed
                );

                setSignalSample(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { signalSample, loading, error };
};

export default useApiVirtualCareGetSignalSample;
