import PropTypes from 'prop-types';
import React from 'react';
import Styled from 'styled-components';

import errorIcon from '@/public/icons/error-icon.svg';
import {colorPalette} from '@/themes/darkmode.js';

class ErrorMessage extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        text: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={`error-message ${props.className}`}>
                {props.errorMessage}
                {props.text && <span><br />{props.text}</span>}
            </div>
        );
    }
}

//language=SCSS
ErrorMessage = Styled(ErrorMessage)`
& {
    padding: 10px 10px 10px 60px;
    border-radius: 5px;
    background-color:${colorPalette.danger};
    background-image: url(${errorIcon});
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 25px;
    margin:10px 0;
    text-align:left;
}
`;

export { ErrorMessage };
