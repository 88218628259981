import styled from 'styled-components';

//import {useTranslation} from 'react-i18next';
import ErrorBoundary from '@/components/errors/error-boundary';
import {InfoMessage} from '@/components/form/info-message';

const PatientVirtualCareErrorPage = () => {
    //const { t } = useTranslation();

    return (
        <StyledWrapper>
            <ErrorBoundary>
                <div>
                    {/*TODO text to be defined*/}
                    <InfoMessage infoMessage={'There is no consultation for this patient at the moment'} />
                </div>
            </ErrorBoundary>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      
  }
`;

export default PatientVirtualCareErrorPage;
