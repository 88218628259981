import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import VirtualCareRealTimeAverage from '@/components/business/virtual-care/real-time/virtual-care-real-time-average';
import VirtualCareRealTimeSpot from '@/components/business/virtual-care/real-time/virtual-care-real-time-spot';
import {ErrorMessage} from '@/components/form/error-message';
import LoadingView from '@/components/static/loading-view';
import useApiVirtualCareGetEventConfiguration from '@/services/api-hooks/useApiVirtualCareGetEventConfiguration';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';

const VirtualCareRealTime = () => {
    const { t } = useTranslation();
    const { eventConfiguration, loading: eventConfigurationLoading, error: eventConfigurationError } = useApiVirtualCareGetEventConfiguration();

    return (
        <StyledWrapper>
            <header>
                <h2>{t(['What is happening?', 'virtualCare.realTime.title'])}</h2>
                <h3>{t(['The caregiver got an alert on the Companion App?', 'virtualCare.realTime.subtitle'])}</h3>
            </header>

            <div className="virtual-care-content" id="virtual-care-content-realTime">
                <div className="virtual-care-realTime-spot-wrapper">
                    <VirtualCareRealTimeSpot signalType={VirtualCareSignalType.SIGNAL_PR} />
                    <VirtualCareRealTimeSpot signalType={VirtualCareSignalType.SIGNAL_SPO2} />
                </div>
                <div className="virtual-care-realTime-average-wrapper">
                    {eventConfigurationLoading && <LoadingView />}
                    {eventConfigurationError && <ErrorMessage errorMessage={eventConfigurationError.message} />}
                    {eventConfiguration &&
                        <>
                            <VirtualCareRealTimeAverage
                                signalType={VirtualCareSignalType.SIGNAL_PR}
                                signalEventConfiguration={eventConfiguration.hr}
                            />
                            <VirtualCareRealTimeAverage
                                signalType={VirtualCareSignalType.SIGNAL_SPO2}
                                signalEventConfiguration={eventConfiguration.spo2}
                            />
                        </>
                    }
                </div>
                {/*<div className="virtual-care-realTime-alerts-wrapper">
                    <VirtualCareRealTimeAlerts />
                </div>*/}
            </div>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      #virtual-care-content-realTime {
          display:flex;
          width: 60%;
          margin: 0 auto;
          margin-top: 40px;
          > div:first-child {
              width: 25%;
              margin: 0 20px;
              > div {
                  margin-bottom: 20px;
              }
          }
          > div.virtual-care-realTime-spot-wrapper {
              width: 40%;
              margin: 0 20px;
              > div {
                  margin-bottom: 20px;
              }
          }
          > div.virtual-care-realTime-average-wrapper {
              width: 60%;
              margin: 0 20px;
              > div {
                  margin-bottom: 20px;
              }
          }/*
          > div.virtual-care-realTime-alerts-wrapper {
              width: 40%;
              margin: 0 20px;
              > div {
                  margin-bottom: 20px;
              }
          }*/
      }
      .virtual-care-realTime-content {
          width: 80%;
          margin: 0 auto;
      }
  }
`;

export default VirtualCareRealTime;
