import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import ColoredContainer from '@/components/layout/colored-container';
import {RedDotBlinking} from '@/components/static/red-dot-blinking';
import {colorPalette} from '@/themes/darkmode';
import {
    getVirtualCareSignalTypeIdentifier,
    getVirtualCareSignalTypeUnit,
    VirtualCareSignalType,
} from '@/types/virtual-care-signal-type';

interface VirtualCareRealTimeSpotProps {
    signalType: VirtualCareSignalType
}

const VirtualCareRealTimeSpot = ({signalType} : VirtualCareRealTimeSpotProps) => {
    const { t } = useTranslation();
    // TODO : GET DATA WITH WEBRTC

    return (
        <StyledWrapper className="virtual-care-block-realTime" id={`virtual-care-realTime-spot-${getVirtualCareSignalTypeIdentifier(signalType)}`}>
            <ColoredContainer
                color={colorPalette.signalType[getVirtualCareSignalTypeIdentifier(signalType)]}
                title={`${t(`global.${getVirtualCareSignalTypeIdentifier(signalType)}`)} - ${t(['real time', 'virtualCare.realTime.spotTitle'])}`}
            >
                <div>
                    <RedDotBlinking/>
                </div>
                <div>
                    <span className="spot-value">74</span>
                    <span className="spot-unit">&nbsp;{getVirtualCareSignalTypeUnit(signalType)}</span>
                </div>
            </ColoredContainer>
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      > div > div {
          display: flex;
          align-items: center;
          margin-top:20px;
          margin-bottom:20px;
      }
      .spot-value {
          font-size: 40px;
      }
      .spot-unit {
          font-size: 16px;
      }
  }
`;

export default VirtualCareRealTimeSpot;
