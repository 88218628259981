import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import styled, { css, keyframes } from 'styled-components';

import {colorPalette} from '@/themes/darkmode';

const kf = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const animation = css`${kf} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`;

type LoadingViewProps = {
    className?: string,
    color?: string,
    deferMs?: number,
}

const LoadingView = ({className, color, deferMs}: LoadingViewProps) => {
    const [visible, setVisible] = useState<boolean>(!deferMs || deferMs <= 0);
    useEffect(() => {
        if (deferMs) {
            setTimeout(() => {
                setVisible(true);
            }, deferMs);
        }
    }, [deferMs]);

    if (!visible) {
        return null;
    }
    else {
        return (
            <StyledWrapper className={`loading-view ${className ?? ''}`} color={color}>
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </StyledWrapper>
        );
    }
};

LoadingView.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    text-align: center;
    line-height: 0;

    &.fullSize {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 80px;
        left: 0;
        background: ${colorPalette.mainBackground};
        z-index: 5000;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        margin-top: 10%;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid ${props => props.color || 'white'};
        border-radius: 50%;
        animation: ${animation};
        border-color: ${props => props.color || 'white'} transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}
`;

export default LoadingView;
