import {CaregiverCommandServiceClient} from 'gabi-api-js/caregiver/command/caregiver_command_grpc_web_pb';
import {CaregiverAddition, PersonalDetailsUpdate} from 'gabi-api-js/caregiver/command/caregiver_command_pb';
import {CaregiverId, PersonalDetails as CaregiverPersonaLDetails} from 'gabi-api-js/caregiver/common/caregiver_common_pb';
import {CaregiverQueryServiceClient} from 'gabi-api-js/caregiver/query/caregiver_query_grpc_web_pb';
import {PhoneNumber} from 'gabi-api-js/hcp/common/hcp_common_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {buildGender} from '@/services/api-requests/requests-utils';

const serviceQueryClient = CaregiverQueryServiceClient;
const serviceCommandClient = CaregiverCommandServiceClient;

export class CaregiverService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'getCaregiver' : {
            return this.getCaregiver(idToken, options.data);
        }
        case 'createCaregiver': {
            return this.createCaregiver(idToken, options.data);
        }
        case 'updateCaregiver': {
            return this.updateCaregiver(idToken, options.data);
        }
        
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    getCaregiver(idToken, data) {
        const req = new CaregiverId();
        req.setId(data.caregiverId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPersonalDetails', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    createCaregiver(idToken, data) {
        const req = new CaregiverAddition();
        req.setPersonalDetails(
            new CaregiverPersonaLDetails()
                .setFirstName(data.caregiver.firstName)
                .setLastName(data.caregiver.lastName)
                .setEmail(data.caregiver.email)
                .setPreferredLanguage(data.caregiver.preferredLanguage)
                .setGender(buildGender(data.caregiver.gender))

                .setPhoneNumber(
                    new PhoneNumber()
                        .setNumber(data.caregiver.phoneNumber.number)
                        .setCountryCode(data.caregiver.phoneNumber.countryCode)
                )
        );
        return (
            grpcRequest(idToken, serviceCommandClient, 'add', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateCaregiver(idToken, data) {
        const req = new PersonalDetailsUpdate();
        req.setCaregiverId(new CaregiverId().setId(data.caregiverId));
        req.setPersonalDetails(
            new CaregiverPersonaLDetails()
                .setFirstName(data.caregiver.firstName)
                .setLastName(data.caregiver.lastName)
                .setEmail(data.caregiver.email)
                .setPreferredLanguage(data.caregiver.preferredLanguage)
                .setGender(buildGender(data.caregiver.gender))

                .setPhoneNumber(
                    new PhoneNumber()
                        .setNumber(data.caregiver.phoneNumber.number)
                        .setCountryCode(data.caregiver.phoneNumber.countryCode)
                )
        );
        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalDetails', req)
                .then(result => {
                    return result;
                })
        );
    }
}
