import {Sex, SkinType} from 'gabi-api-js/vct/common/vct_common_pb';
import { useState, useEffect } from 'react';

import {
    parseApiSexToVirtualCareSex,
    parseApiSkinTypeToVirtualCareSkinType
} from '@/services/api-requests/virtual-care-requests-utils';
import {BackendApiService} from '@/services/backend-api-service';
import VirtualCarePatientMockData from '@/services/mock-data/virtual-care-patient-mock-data';
import RequestError from '@/types/request-error';
import VirtualCareApiDate from '@/types/virtual-care-api-date';
import VirtualCarePatient from '@/types/virtual-care-patient';

const useApiVirtualCareGetPatient = () => {
    const [patientDetails, setPatientDetails] = useState<VirtualCarePatient | null>(null);
    const [loading, setLoading] = useState<boolean | null>(true);
    const [error, setError] = useState<RequestError | null>(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        BackendApiService.getRequest({
            domain: 'virtualCare',
            modelName: 'getPatient',
        })
            .then((response: { [key:string]:unknown }) => {
                const birthDateParsed = new VirtualCareApiDate(
                    VirtualCarePatientMockData.birthDate.year,
                    VirtualCarePatientMockData.birthDate.month,
                    VirtualCarePatientMockData.birthDate.day,
                );
                const responseParsed = new VirtualCarePatient(
                    response.firstName as string,
                    response.lastName as string,
                    birthDateParsed,
                    parseApiSexToVirtualCareSex(response.sex as Sex),
                    parseApiSkinTypeToVirtualCareSkinType(response.skinType as SkinType),
                );
                setPatientDetails(responseParsed);
                setLoading(false);
            })
            .catch((err: RequestError) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return { patientDetails, loading, error };
};

export default useApiVirtualCareGetPatient;
