import {JSXElementConstructor, useMemo, useState} from 'react';

import {TableSortableHead} from '@/components/table/table-sortable-head';
import {TableSortableColumn, TableSorting} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';

type TableSortableProps<DataItem> = {
    TableBodyRowComponent: JSXElementConstructor<{
        data: DataItem;
    }>;
    columns: TableSortableColumn<DataItem>[];
    data: DataItem[];
    defaultSorting?: TableSorting;
    keyFn: (item: DataItem) => string;
}

function sortData<DataItem>(data: DataItem[], columns: TableSortableColumn<DataItem>[], tableSorting: TableSorting) {
    const out = [ ...data ];
    const sortColumn = (columns ?? []).find(c => c.id === tableSorting.id);
    if (!sortColumn) {
        console.warn(`Unknown sort column '${tableSorting.id}. No sorting done`);
        return out;
    }
    if (!sortColumn.compareFn) {
        console.warn(`No compare function set for sort column '${tableSorting.id}. No sorting done`);
        return out;
    }
    if (tableSorting.sortByOrder === 'default') {
        return out;
    }
    else {
        out.sort(sortColumn.compareFn);
        if (tableSorting.sortByOrder === 'desc') {
            out.reverse();
        }
        return out;
    }
}

function TableSortable<DataItem>({ TableBodyRowComponent, columns, data, keyFn, defaultSorting }: TableSortableProps<DataItem>) {
    const firstSortableColumn = columns?.filter(c => c.sortable)[0] ?? { id: '', sortable: true, label: '' };
    const [tableSorting, setTableSorting] = useState<TableSorting>(defaultSorting ?? {
        id: firstSortableColumn.id,
        sortByOrder: 'default',
    });

    const tableData = useMemo(
        () => {
            return sortData(data ?? [], columns, tableSorting);
        },
        [columns, data, tableSorting]
    );

    if (data && columns && tableSorting) {
        return (
            <table>
                <TableSortableHead columns={columns} tableSorting={tableSorting} onSortingChange={setTableSorting} />
                <TableSortableBody
                    keyFn={keyFn}
                    tableData={tableData}
                    TableBodyRowComponent={TableBodyRowComponent}
                />
            </table>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortable" />;
    }
}

type TableSortableBodyProps<DataItem> = {
    tableData: DataItem[];
    TableBodyRowComponent: JSXElementConstructor<{
        data: DataItem;
    }>;
    keyFn: (item: DataItem) => string;
}
function TableSortableBody<DataItem>({ keyFn, tableData, TableBodyRowComponent }: TableSortableBodyProps<DataItem>) {
    if (tableData) {
        return (
            <tbody>
                {tableData.map((item) => (
                    <TableBodyRowComponent key={`body-entry__${keyFn(item)}`} data={item} />
                ))}
            </tbody>
        );
    }
    else {
        return <ComponentErrorMessage component="TableSortableBody" />;
    }
}

export const testables = {
    TableSortableBody
};

export {TableSortable};
