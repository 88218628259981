import {Auth0Service} from '@/services/auth0-service';

let transportService = null;
let componentTesting = false;

/**
 * This services handles the communication with the Backend API.
 * The actual calls are not made in this service. It needs a transportService which handles them, whatever the protocol used
 */
const BackendApiService = {
    setTransportService(service) {
        transportService = service;
    },

    setComponentTesting(testing) {
        componentTesting = testing;
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequest(options) {
        options = Object.assign({
            ttl: 3600,
            filter: null,
        }, options);

        if(componentTesting) {
            return (
                transportService.getRequest('', options)
                    .then(data => {
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        throw err;
                    })
            );
        }
        else {
            return (
                Auth0Service.getAccessToken()
                    .then(accessToken => transportService.getRequest(accessToken, options))
                    .then(data => {
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        throw err;
                    })
            );
        }
        
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - cached: (default true; only: readAll, readOne) caches the result of the request for future use. If false, forces the request to be executed again
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequestWithoutToken(options) {
        options = Object.assign({
            ttl: 3600,
            filter: null,
        }, options);

        return (
            transportService.getRequest('', options)
                .then(data => {
                    return data;
                })
                .catch(err => {
                    console.error(err);
                    throw err;
                })
        );
    },

    /**
     * Reads all elements of a model matching a filter (all if no filter provided)
     * @param modelName the name of the model to read
     * @param domainName the domain where the modelName is located
     * @param filter json-compatible object for the filter query
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchAll(modelName, domainName, filter, options) {
        return (
            this.getRequest({
                ...options,
                domain: domainName,
                modelName: modelName,
                method: 'readAll',
            })
        );
    },

    /**
     * Reads one element of a model, identified by its id.
     * @param modelName the name of the model to read
     * @param domainName the domain where the modelName is located
     * @param id id of the entity to read
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchOne(modelName, domainName, id, options) {
        return (
            this.getRequest(Object.assign({
                modelName: modelName,
                domain: domainName,
                method: 'readOne',
                id: id,
            }, options))
        );
    },
};

window.BackendApiService = BackendApiService;

export { BackendApiService };
//----------------------------------------------------------------------------------------------------------------------
