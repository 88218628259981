import {useSyncExternalStore} from 'react';
import { v4 as uuid } from 'uuid';

import config from '@/config';
import {ObservableVar} from '@/util/observable-var';

const messageLifetime = config.feedbackAlerts.lifetime * 1000;

export enum FeedbackAlertType {
    SUCCESS = 1,
    ERROR = 2,
}

export type FeedbackAlert = {
    apiFunction: string;
    code?: string;
    id: string; // UUIDv4
    message: string;
    type: FeedbackAlertType;
}

class FeedbackAlertsService_ {
    readonly feedbackAlerts = new ObservableVar<FeedbackAlert[]>([]);

    add(type: FeedbackAlertType, apiFunction: string, message: string, code?: string) {
        const alert: FeedbackAlert = {
            type: type,
            apiFunction: apiFunction,
            message: message,
            code: code, // Optional
            id: uuid(),
        };

        this.feedbackAlerts.set([
            ...this.feedbackAlerts.value,
            alert,
        ]);
        //store.dispatch(feedbackAlerts.actions.add(alert));

        if (alert.type === FeedbackAlertType.SUCCESS) {
            setTimeout(() => {
                this.remove(alert.id);
            }, messageLifetime);
        }
    }

    remove(alertId: string) {
        this.feedbackAlerts.set(this.feedbackAlerts.value.filter(item => {
            return item.id !== alertId;
        }));
        //store.dispatch(feedbackAlerts.actions.remove(alert));
    }
}

const FeedbackAlertsService = new FeedbackAlertsService_();

function useFeedbackAlerts() {
    return useSyncExternalStore<FeedbackAlert[]>(
        FeedbackAlertsService.feedbackAlerts.onChange,
        () => FeedbackAlertsService.feedbackAlerts.value,
    );
}

export { FeedbackAlertsService, useFeedbackAlerts };
