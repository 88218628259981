import VirtualCareApiDate from '@/types/virtual-care-api-date';
import VirtualCareSex from '@/types/virtual-care-sex';
import VirtualCareSkinType from '@/types/virtual-care-skin-type';

class VirtualCarePatient {
    constructor(
        readonly firstName: string,
        readonly lastName: string,
        readonly birthDate: VirtualCareApiDate,
        readonly sex: VirtualCareSex,
        readonly skinType: VirtualCareSkinType,
    ) {}
}

export default VirtualCarePatient;
