import {useTranslation} from 'react-i18next';

import ComponentErrorMessage from '@/components/widgets/component-error-message';
import patientCrawling_skinType_1 from '@/public/images/baby_crawling_skin_1.svg';
import patientCrawling_skinType_2 from '@/public/images/baby_crawling_skin_2.svg';
import patientCrawling_skinType_3 from '@/public/images/baby_crawling_skin_3.svg';
import patientCrawling_skinType_4 from '@/public/images/baby_crawling_skin_4.svg';
import patientCrawling_skinType_5 from '@/public/images/baby_crawling_skin_5.svg';
import patientCrawling_skinType_6 from '@/public/images/baby_crawling_skin_6.svg';
import VirtualCareSkinType from '@/types/virtual-care-skin-type';

type VirtualCarePatientCrawlingAvatarProps = {
    skinType: VirtualCareSkinType | undefined
};

const VirtualCarePatientCrawlingAvatar = ({ skinType }: VirtualCarePatientCrawlingAvatarProps) => {
    const { t } = useTranslation();

    const patientCrawlingSkinType = () => {
        switch(skinType) {
        case VirtualCareSkinType.FITZPATRICK_I:
            return patientCrawling_skinType_1;
        case VirtualCareSkinType.FITZPATRICK_II:
            return patientCrawling_skinType_2;
        case VirtualCareSkinType.FITZPATRICK_III:
            return patientCrawling_skinType_3;
        case VirtualCareSkinType.FITZPATRICK_IV:
            return patientCrawling_skinType_4;
        case VirtualCareSkinType.FITZPATRICK_V:
            return patientCrawling_skinType_5;
        case VirtualCareSkinType.FITZPATRICK_VI:
            return patientCrawling_skinType_6;
        default:
            return patientCrawling_skinType_1;
        }
    };

    if(skinType) {
        return (
            <img className="baby-crawling-img" src={patientCrawlingSkinType()} alt={t(['Baby crawling', 'virtualCare.patient.crawlingImageAlt']).toString()} width={70}/>
        );
    } else {
        return <ComponentErrorMessage component="VirtualCarePatientCrawlingAvatar" />;
    }
};

export default VirtualCarePatientCrawlingAvatar;
