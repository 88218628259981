import { SignalCommandServiceClient } from 'gabi-api-js/signal/command/signal_command_grpc_web_pb';
import {
    UpdateEventConfigurationRequest
} from 'gabi-api-js/signal/command/signal_command_pb';
import { PatientId } from 'gabi-api-js/signal/common/signal_common_pb';
import { SignalQueryServiceClient } from 'gabi-api-js/signal/query/signal_query_grpc_web_pb';
import {
    DateQuery,
    SignalSampleQuery,
    SignalType,
    SignalQuery,
} from 'gabi-api-js/signal/query/signal_query_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {RequestsService} from '@/services/api-requests/requests-service';
import {
    buildDate,
    buildFullDate,
    buildSignalType,
    buildEventConfiguration,
    formatApiFullDateToJSDate,
    formatJSDateToApiDate, formatJSDateToApiFullDate,
} from '@/services/api-requests/requests-utils';

const serviceQueryClient = SignalQueryServiceClient;
const serviceCommandClient = SignalCommandServiceClient;

export class SignalService extends RequestsService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'updateEventConfiguration' : {
            return this.updateEventConfiguration(idToken, options.data);
        }
        case 'getRecordingDuration' : {
            return this.getRecordingDuration(idToken, options.data);
        }
        case 'getSignal': {
            return this.getSignal(idToken, options.data);
        }
        case 'getSignalSample': {
            return this.getSignalSample(idToken, options.data);
        }

        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    getRecordingDuration(idToken, data) {
        const req = new DateQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setDate(buildDate(formatJSDateToApiDate(data.date)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getRecordingDuration', req)
                .then(result => {
                    return result;
                })
        );
    }

    getSignal(idToken, data) {
        return Promise.all([
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_PR),
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_SPO2),
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_ACTIGRAPHY),
        ]).then(([ hrData, spo2Data, actigraphy ]) => {
            return {
                rangesList: {
                    hr: [hrData.valueMin, hrData.valueMax],
                    spo2: [spo2Data.valueMin, spo2Data.valueMax],
                    actigraphy: [actigraphy.valueMin, actigraphy.valueMax],
                },
                blocksList: {
                    hr: hrData.blocksList,
                    spo2: spo2Data.blocksList,
                    actigraphy: actigraphy.blocksList,
                },
                eventsList: {
                    hr: hrData.eventsList,
                    spo2: spo2Data.eventsList,
                    actigraphy: actigraphy.eventsList,
                }
            };
        });
    }

    getSignalForSignalType(idToken, data, signalType) {
        const req = new SignalQuery();
        req.setFrom(buildDate(formatJSDateToApiDate(data.from)));
        req.setTo(buildDate(formatJSDateToApiDate(data.to)));
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setSignalType(signalType);
        return grpcRequest(idToken, SignalQueryServiceClient, 'getSignal', req)
            .then(allResults => {
                allResults.blocksList.forEach(item => {
                    item.atOrig = item.at;
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                allResults.eventsList.forEach(item => {
                    item.from = formatApiFullDateToJSDate(item.from).getTime();
                    item.to = formatApiFullDateToJSDate(item.to).getTime();
                });
                return allResults;
            });
    }

    getSignalSample(idToken, data) {
        const req = new SignalSampleQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.dateFrom)));
        req.setDurationInSeconds(data.duration);
        req.setSignalType(buildSignalType(data.signalType));
        return grpcRequest(idToken, SignalQueryServiceClient, 'getSignalSample', req)
            .then(allResults => {
                allResults.signalData.blocksList.forEach(item => {
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                allResults.signalData.eventsList.forEach(item => {
                    item.from = formatApiFullDateToJSDate(item.from).getTime();
                    item.to = formatApiFullDateToJSDate(item.to).getTime();
                });
                allResults.actigraphyData.blocksList.forEach(item => {
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                return allResults;
            });
    }

    updateEventConfiguration(idToken, data) {
        const req = new UpdateEventConfigurationRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setEventConfiguration(buildEventConfiguration(data.eventConfiguration));
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateEventConfiguration', req)
                .then(result => {
                    return result;
                })
        );
    }
}
