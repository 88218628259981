import VirtualCareAggregatedSignalDataBlock from '@/types/virtual-care-aggregated-signal-data-block';
import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';

class VirtualCareAggregatedSignalData {
    constructor(
        readonly signalType: VirtualCareSignalType,
        readonly blocksList: Array<VirtualCareAggregatedSignalDataBlock>,
    ) {
    }

}

export default VirtualCareAggregatedSignalData;
